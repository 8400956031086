import React from 'react';
import { Box, Button, IconButton } from '@mui/joy';
import { FirstPage, LastPage, ArrowBack, ArrowForward } from '@mui/icons-material';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxVisiblePages = 5;
  const halfVisible = Math.floor(maxVisiblePages / 2);
  
  let startPage = Math.max(1, currentPage - halfVisible);
  let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
  
  if (endPage - startPage + 1 < maxVisiblePages) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  const handlePrevious = () => {
    const newPage = Math.max(1, currentPage - maxVisiblePages);
    onPageChange(newPage);
  };

  const handleNext = () => {
    const newPage = Math.min(totalPages, currentPage + maxVisiblePages);
    onPageChange(newPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          variant={currentPage === i ? 'solid' : 'outlined'}
          onClick={() => onPageChange(i)}
          sx={{ mx: 0.5 }}
        >
          {i}
        </Button>
      );
    }

    return pageNumbers;
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
      <IconButton 
        onClick={() => onPageChange(1)} 
        disabled={currentPage === 1}
        sx={{ mr: 1 }}
      >
        <FirstPage />
      </IconButton>
      <IconButton 
        onClick={handlePrevious} 
        disabled={currentPage <= maxVisiblePages}
        sx={{ mr: 1 }}
      >
        <ArrowBack />
      </IconButton>
      {renderPageNumbers()}
      <IconButton 
        onClick={handleNext} 
        disabled={currentPage > totalPages - maxVisiblePages}
        sx={{ ml: 1 }}
      >
        <ArrowForward />
      </IconButton>
      <IconButton 
        onClick={() => onPageChange(totalPages)} 
        disabled={currentPage === totalPages}
        sx={{ ml: 1 }}
      >
        <LastPage />
      </IconButton>
    </Box>
  );
};

export default Pagination;