import React from 'react';
import Table from '@mui/joy/Table';
import Checkbox from '@mui/joy/Checkbox';
import { useArticleContext } from '../contexts/ArticleContext';
import ArticleItem from './ArticleItem';
import ArticleListSkeleton from './ArticleListSkeleton';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';

const ArticleList = () => {
  const { 
    selectedArticles, 
    toggleAllArticles, 
    articles,
    loading
  } = useArticleContext();

  const handleSelectAll = (event) => {
    toggleAllArticles();
  };

  if (loading) {
    return <ArticleListSkeleton />;
  }

  if (articles.length === 0) {
    return (
      <Sheet variant="outlined" sx={{ borderRadius: 'sm', p: 3 }}>
        <Typography level="body-lg" textAlign="center">
          No articles found. Try refreshing or go to create your content first!
        </Typography>
      </Sheet>
    );
  }

  return (
    <Table
      stripe="odd"
      hoverRow
      sx={{
        captionSide: 'top',
        '& tbody': { bgcolor: 'background.surface' },
        '& th': { fontWeight: 'bold' }
      }}
    >
      <thead>
        <tr>
          <th style={{ width: '5%' }}>
            <Checkbox
              checked={selectedArticles.length === articles.length && articles.length > 0}
              indeterminate={selectedArticles.length > 0 && selectedArticles.length < articles.length}
              onChange={handleSelectAll}
            />
          </th>
          <th style={{ width: '20%' }}>Video Title / File Name</th>
          <th style={{ width: '20%' }}>Source</th>
          <th style={{ width: '10%' }}>Status</th>
          <th style={{ width: '10%' }}>Video Duration</th>
          <th style={{ width: '10%' }}>Process Time</th>
          <th style={{ width: '25%' }}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {articles.map((article) => (
          <ArticleItem key={article.id} article={article} />
        ))}
      </tbody>
    </Table>
  );
};

export default React.memo(ArticleList);