import React, { useState } from 'react';
import { Typography, Box, Input, Button, Modal, CircularProgress, Tooltip, List, ListItem, ListItemDecorator } from '@mui/joy';
import RefreshIcon from '@mui/icons-material/Refresh';
import LogoutIcon from '@mui/icons-material/Logout';
import FadeAlert from './FadeAlert';
import DefaultPostingSettings from './DefaultPostingSettings.js';
import DefaultPostingSettingsSkeleton from './DefaultPostingSettingsSkeleton.js';
import useWordPressSettings from '../hooks/useWordPressSettings';


function WordPressIntegration() {
  const { 
    settings,
    isLoading, 
    error,
    refreshSettings,
    testConnection,
    saveCredentials,
    savePostingSettings,
    disconnect,
  } = useWordPressSettings();

  const [siteUrl, setSiteUrl] = useState('');
  const [username, setUsername] = useState('');
  const [applicationPassword, setApplicationPassword] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isTestingConnection, setIsTestingConnection] = useState(false);
  const [isSavingCredentials, setIsSavingCredentials] = useState(false);
  const [isLoadingPostingSettings, setIsLoadingPostingSettings] = useState(false);

  const handleDisconnect = async () => {
    try {
      await disconnect();
      setAlert({ message: 'Successfully disconnected from WordPress', severity: 'success' });
      // Clear local form state
      setSiteUrl('');
      setUsername('');
      setApplicationPassword('');
      setShowConfirmModal(false);  // Add this line to close the modal
    } catch (error) {
      setAlert({ message: `Failed to disconnect: ${error.message}`, severity: 'error' });
      setShowConfirmModal(false);  // Also close the modal on error
    }
  };

  const handleSaveCredentials = async () => {
    setIsSavingCredentials(true);
    setIsLoadingPostingSettings(true);
    try {
      const cleanSiteUrl = siteUrl.replace(/\/+$/, ''); // Remove trailing slashes
      await saveCredentials({ siteUrl: cleanSiteUrl, username, applicationPassword });
      setAlert({ message: 'Credentials saved successfully!', severity: 'success' });
      await refreshSettings();
    } catch (error) {
      setAlert({ message: `Failed to save credentials: ${error.message}`, severity: 'error' });
    } finally {
      setIsSavingCredentials(false);
      setIsLoadingPostingSettings(false);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    setAlert(null);
    try {
      await refreshSettings();
      setAlert({ message: 'Settings refreshed successfully', severity: 'success' });
    } catch (error) {
      setAlert({ message: 'Failed to refresh settings. Please try again.', severity: 'error' });
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleTestConnection = async () => {
    setIsTestingConnection(true);
    try {
      const cleanSiteUrl = siteUrl.replace(/\/+$/, ''); // Remove trailing slashes
      console.log('Sending test connection request:', { siteUrl: cleanSiteUrl, username, applicationPassword });
      const response = await testConnection({ siteUrl: cleanSiteUrl, username, applicationPassword });
      console.log('Test connection response:', response);
      if (response && response.success) {
        setAlert({ message: response.message || 'Connection test successful!', severity: 'success' });
      } else {
        setAlert({ message: response.message || 'Connection test failed', severity: 'error' });
      }
    } catch (error) {
      console.error('Test connection error:', error);
      setAlert({ message: `Connection test failed: ${error.message}`, severity: 'error' });
    } finally {
      setIsTestingConnection(false);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const isConnected = settings && settings.isConnected;
  const credentials = settings && settings.credentials;
  const postingSettings = settings && settings.postingSettings;

  return (
    <Box>
      <Typography level="h4" sx={{ mb: 2 }}>WordPress Integration</Typography>
      
      {error ? (
        <>
          <FadeAlert message="Failed to load WordPress settings. Please disconnect and try again." severity="error" />
          <Box sx={{ mt: 2 }}>
            <Button 
              onClick={handleDisconnect} 
              color="danger" 
              startDecorator={<LogoutIcon />}
            >
              Disconnect
            </Button>
          </Box>
        </>
      ) : isConnected ? (
        <>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            mb: 2, 
            bgcolor: 'background.level1', 
            p: 2, 
            borderRadius: 'sm',
            width: '100%'
          }}>
            <Typography>Connected site: {credentials.siteUrl}</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                startDecorator={<RefreshIcon />}
                onClick={handleRefresh}
                loading={isRefreshing}
                loadingPosition="start"
                variant="outlined"
                color="primary"
                size="sm"
              >
                Refresh
              </Button>
              <Button 
                onClick={() => setShowConfirmModal(true)} 
                color="danger" 
                variant="outlined"
                size="sm"
                startDecorator={<LogoutIcon />}
              >
                Disconnect
              </Button>
            </Box>
          </Box>
          {isLoadingPostingSettings ? (
            <DefaultPostingSettingsSkeleton />
          ) : postingSettings ? (
            <DefaultPostingSettings 
              connectedSite={credentials.siteUrl}
              categoriesAndTags={postingSettings.categoriesAndTags}
              defaultSettings={postingSettings.defaultSettings}
              onSaveSuccess={() => setAlert({ message: 'Settings saved', severity: 'success' })} 
              onSaveSettings={savePostingSettings}
            />
          ) : (
            <Typography color="danger">Failed to load posting settings</Typography>
          )}
        </>
      ) : (
        <>
          <Box sx={{ mb: 2 }}>
            <Typography>Site URL</Typography>
            <Input value={siteUrl} onChange={(e) => setSiteUrl(e.target.value)} placeholder="https://your-wordpress-site.com" fullWidth />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography>Username</Typography>
            <Input value={username} onChange={(e) => setUsername(e.target.value)} fullWidth />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography>Application Password</Typography>
            <Input 
              type="password" 
              value={applicationPassword} 
              onChange={(e) => setApplicationPassword(e.target.value)} 
              fullWidth 
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Button 
              onClick={handleTestConnection} 
              disabled={isTestingConnection}
            >
              {isTestingConnection ? 'Testing...' : 'Test Connection'}
            </Button>
            <Button 
              onClick={handleSaveCredentials} 
              disabled={isSavingCredentials}
            >
              {isSavingCredentials ? 'Saving...' : 'Save Credentials'}
            </Button>
          </Box>
          
          {alert && (
            <FadeAlert
              message={alert.message}
              severity={alert.severity}
              onClose={() => setAlert(null)}
            />
          )}

          <Box sx={{ bgcolor: 'background.level1', p: 2, borderRadius: 'sm' }}>
            <Typography level="body-md" fontWeight="bold" sx={{ mb: 2 }}>How to create an Application Password:</Typography>
            <List>
              <ListItem>
                <ListItemDecorator>1.</ListItemDecorator>
                Log in to your WordPress admin panel
              </ListItem>
              <ListItem>
                <ListItemDecorator>2.</ListItemDecorator>
                Go to Users → Profile (or Your Profile)
              </ListItem>
              <ListItem>
                <ListItemDecorator>3.</ListItemDecorator>
                Scroll down to the "Application Passwords" section
              </ListItem>
              <ListItem>
                <ListItemDecorator>4.</ListItemDecorator>
                Enter a name for your application password (e.g., "8motions Integration")
              </ListItem>
              <ListItem>
                <ListItemDecorator>5.</ListItemDecorator>
                Click "Add New Application Password"
              </ListItem>
              <ListItem>
                <ListItemDecorator>6.</ListItemDecorator>
                Copy the generated password immediately (it won't be shown again)
              </ListItem>
              <ListItem>
                <ListItemDecorator>7.</ListItemDecorator>
                Paste the password in the "Application Password" field above
              </ListItem>
            </List>
          </Box>
        </>
      )}
      
      <Modal open={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm Disconnection
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to disconnect from {credentials?.siteUrl}?
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setShowConfirmModal(false)} color="neutral" sx={{ mr: 1 }}>Cancel</Button>
            <Button onClick={handleDisconnect} color="danger">Disconnect</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default React.memo(WordPressIntegration);