import { useState, useCallback } from 'react';

const usePagination = (fetchArticles) => {
  const [page, setPage] = useState(1);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
    fetchArticles(newPage);
  }, [fetchArticles]);

  return { page, handlePageChange };
};

export default usePagination;