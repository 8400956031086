import React, { useState, useEffect } from 'react';
import { Typography, Box, Tabs, TabList, Tab, TabPanel, Button, Avatar, Stack, Modal, Sheet } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';

function Account() {
  const navigate = useNavigate();
  const pb = new PocketBase(process.env.REACT_APP_PB_URL || 'https://log.8motions.com');
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('pocketbase_auth'));
    if (authData && authData.record) {
      setUser(authData.record);
    }
  }, []);

  const handleLogout = async () => {
    try {
      await pb.authStore.clear();
      localStorage.removeItem('pocketbase_auth');
      localStorage.removeItem('pocketbase_token');
      window.location.href = '/login';
    } catch (error) {
      console.error('Error during logout:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography level="h2" sx={{ mb: 2 }}>Account</Typography>
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        sx={{ borderRadius: 'lg' }}
      >
        <TabList>
          <Tab>Profile</Tab>
          <Tab>Subscription</Tab>
        </TabList>
        <TabPanel value={0} sx={{ p: 2 }}>
          <Typography level="h3" sx={{ mb: 2 }}>Profile Information</Typography>
          {user && (
            <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 4 }}>
              <Avatar src={user.avatar} alt={user.name} sx={{ width: 80, height: 80 }} />
              <Box>
                <Typography level="h4">{user.name}</Typography>
                <Typography level="body-sm">{user.email}</Typography>
              </Box>
            </Stack>
          )}
          <Button 
            variant="soft" 
            color="danger" 
            onClick={() => setOpenLogoutModal(true)}
            sx={{ mt: 2 }}
          >
            Logout
          </Button>
        </TabPanel>
        <TabPanel value={1} sx={{ p: 2 }}>
          <Typography level="h3" sx={{ mb: 2 }}>Subscription Details</Typography>
          <Button variant="outlined" color="primary">Manage Subscription</Button>
        </TabPanel>
      </Tabs>

      <Modal
        aria-labelledby="logout-modal-title"
        open={openLogoutModal}
        onClose={() => setOpenLogoutModal(false)}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography
            component="h2"
            id="logout-modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Confirm Logout
          </Typography>
          <Typography mb={3}>
            Are you sure you want to log out?
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={() => setOpenLogoutModal(false)}>
              Cancel
            </Button>
            <Button variant="solid" color="danger" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </Box>
  );
}

export default Account;