import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { useArticles, useNotification } from '../hooks/hooksExport';
import useWordPressSettings from '../hooks/useWordPressSettings';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';
import api, { publishToWordPress as apiPublishToWordPress } from '../utils/api';

const ArticleContext = createContext();

export const ArticleProvider = React.memo(({ children }) => {
  const { articles, loading, error, totalPages, currentPage, handlePageChange, fetchArticles } = useArticles();
  
  const { notification, showNotification, closeNotification } = useNotification();
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [articleToPublish, setArticleToPublish] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState({ open: false, action: null });
  const [progressModal, setProgressModal] = useState({ open: false, progress: 0, total: 0, minimized: false });
  const [undoAction, setUndoAction] = useState(null);
  const [batchSize] = useState(5);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [articlesToDelete, setArticlesToDelete] = useState([]);

  const { settings: wpSettings, loadWordPressSettings } = useWordPressSettings();

  const pb = useMemo(() => new PocketBase(process.env.REACT_APP_PB_URL || 'https://log.8motions.com'), []);

  const closePublishModal = useCallback(() => {
    console.log('closePublishModal called');
    setOpenPublishModal(false);
    setArticleToPublish(null);
  }, []);

  const handlePublish = useCallback((article) => {
    console.log('handlePublish called', article);
    setArticleToPublish(article);
    setOpenPublishModal(true);
  }, []);

  const publishToWordPress = useCallback(async (articles, options, onProgress) => {
    console.log('publishToWordPress called', articles, options);
    setIsLoading(true);
    let publishedCount = 0;
    let errors = [];

    try {
      if (!Array.isArray(articles) || articles.length === 0) {
        throw new Error('No articles selected for publishing');
      }

      const flatArticles = articles.flat();

      for (const [index, article] of flatArticles.entries()) {
        if (!article || !article.id) {
          console.error('Invalid article object:', article);
          errors.push(`Invalid article object: ${JSON.stringify(article)}`);
          continue;
        }

        try {
          console.log('Publishing article:', article.id);
          const result = await apiPublishToWordPress(article.id, options);
          console.log('Publish result:', result);
          if (result && result.wpPostId) {
            publishedCount++;
            onProgress(publishedCount);
          } else {
            errors.push(`Failed to publish article ${article.id}: No WordPress post ID returned`);
          }
        } catch (error) {
          console.error(`Error publishing article ${article.id}:`, error);
          errors.push(`Failed to publish article ${article.id}: ${error.message}`);
        }
      }

      if (publishedCount > 0) {
        showNotification({
          title: 'Success',
          message: `Published ${publishedCount} article(s) to WordPress`,
          color: 'success',
          duration: 5000
        });
      } else {
        throw new Error('No articles were successfully published');
      }

    } catch (error) {
      console.error('Error in publishToWordPress:', error);
      showNotification({
        title: 'Error',
        message: `Failed to publish articles: ${error.message}`,
        color: 'error',
        duration: 5000
      });
      throw error;
    } finally {
      setIsLoading(false);
    }

    if (errors.length > 0) {
      console.error('Errors during publishing:', errors);
    }

    return publishedCount;
  }, [showNotification, setIsLoading]);

  const toggleArticleSelection = useCallback((id) => {
    setSelectedArticles(prev => 
      prev.includes(id) ? prev.filter(articleId => articleId !== id) : [...prev, id]
    );
  }, []);

  const handleView = useCallback((id) => {
    console.log('handleView called with id:', id);
    window.open(`/view-article/${id}`, '_blank');
  }, []);

  const handleSave = useCallback(async (id) => {
    const article = articles.find(a => a.id === id);
    if (article) {
      try {
        // Implement save logic using PocketBase
        await pb.collection('VideoProcessingLogs').update(id, { saved: true });
        showNotification({
          title: 'Article saved',
          message: 'The article has been successfully saved',
          color: 'success'
        });
        fetchArticles(1); // Refresh the article list
      } catch (error) {
        showNotification({
          title: 'Save failed',
          message: 'Failed to save the article. Please try again.',
          color: 'error'
        });
      }
    }
  }, [articles, fetchArticles, showNotification]);

  const handleCopy = useCallback(async (content) => {
    try {
      const contentElement = document.createElement('div');
      contentElement.innerHTML = content;

      // Extract text content
      const textContent = contentElement.innerText;

      // Extract image URLs
      const imageUrls = Array.from(contentElement.querySelectorAll('img'))
        .map(img => img.src);

      // Combine text and images into a single string
      const combinedContent = `${textContent}\n\n${imageUrls.join('\n')}`;

      // Create a blob with HTML content
      const htmlBlob = new Blob([content], { type: 'text/html' });

      // Create a blob with plain text content
      const textBlob = new Blob([combinedContent], { type: 'text/plain' });

      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': htmlBlob,
          'text/plain': textBlob,
        }),
      ]);

      showNotification({
        title: 'Copied',
        message: 'Article content copied to clipboard',
        color: 'success'
      });
    } catch (err) {
      console.error('Failed to copy content: ', err);
      showNotification({
        title: 'Copy failed',
        message: 'Failed to copy the article content',
        color: 'error'
      });
    }
  }, [showNotification]);

  const handleDelete = useCallback((id) => {
    setArticlesToDelete([id]);
    setOpenDeleteModal(true);
  }, []);

  const handleBulkDelete = useCallback(() => {
    setArticlesToDelete(selectedArticles);
    setOpenDeleteModal(true);
  }, [selectedArticles]);

  const confirmDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      await Promise.all(articlesToDelete.map(id => pb.collection('VideoProcessingLogs').delete(id)));
      showNotification({
        title: 'Success',
        message: `Deleted ${articlesToDelete.length} article(s)`,
        color: 'success'
      });
      fetchArticles(currentPage);
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'Failed to delete articles. Please try again.',
        color: 'error'
      });
    } finally {
      setIsLoading(false);
      setOpenDeleteModal(false);
      setArticlesToDelete([]);
    }
  }, [articlesToDelete, fetchArticles, currentPage, showNotification]);

  const handleRetry = useCallback(async (id) => {
    try {
      // Implement retry logic using PocketBase
      await pb.collection('VideoProcessingLogs').update(id, { status: 'pending' });
      showNotification({
        title: 'Retry initiated',
        message: 'The article processing has been restarted',
        color: 'success'
      });
      fetchArticles(1); // Refresh the article list
    } catch (error) {
      showNotification({
        title: 'Retry failed',
        message: 'Failed to retry processing the article. Please try again.',
        color: 'error'
      });
    }
  }, [fetchArticles, showNotification]);

  const handleBulkAction = useCallback(async (action, options) => {
    if (action === 'publish') {
      setIsLoading(true);
      try {
        const articlesToPublish = selectedArticles.map(id => articles.find(a => a.id === id));
        await publishToWordPress(articlesToPublish, options);
        console.log('Publishing successful');
        showNotification({
          title: 'Success',
          message: `Published ${selectedArticles.length} article(s) to WordPress`,
          color: 'success'
        });
        closePublishModal();
      } catch (error) {
        console.error('Publishing failed', error);
        showNotification({
          title: 'Error',
          message: 'Failed to publish articles. Please try again.',
          color: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    } else if (action === 'delete') {
      handleBulkDelete();
    }
    // ... other actions ...
  }, [selectedArticles, articles, publishToWordPress, showNotification, fetchArticles, currentPage, setIsLoading, closePublishModal, handleBulkDelete]);

  const handlePublishSuccess = useCallback(() => {
    setOpenPublishModal(false);
    fetchArticles(1);
    showNotification({
      title: 'Article published',
      message: 'The article has been successfully published to WordPress',
      color: 'success'
    });
  }, [fetchArticles, showNotification]);

  const undoBulkAction = useCallback(() => {
    // Implement undo logic
  }, []);

  const processBatch = useCallback(async (ids, action) => {
    const batchIds = ids.slice(0, batchSize);
    const remainingIds = ids.slice(batchSize);

    try {
      switch (action) {
        case 'delete':
          await Promise.all(batchIds.map(id => pb.collection('VideoProcessingLogs').delete(id)));
          break;
        case 'publish':
          await Promise.all(batchIds.map(async (id) => {
            const article = articles.find(a => a.id === id);
            if (article && article.status === 'completed') {
              // Implement publish logic here
              await pb.collection('VideoProcessingLogs').update(id, { wordpress_status: 'published' });
            }
          }));
          break;
        // Add more cases for other bulk actions
      }

      setProgressModal(prev => ({
        ...prev,
        progress: prev.progress + batchIds.length
      }));

      if (remainingIds.length > 0) {
        await processBatch(remainingIds, action);
      } else {
        setProgressModal(prev => ({ ...prev, open: false }));
        fetchArticles(1);
        showNotification({
          title: 'Bulk action completed',
          message: `${action} action completed for ${ids.length} articles`,
          color: 'success'
        });
      }
    } catch (error) {
      console.error('Error in batch processing:', error);
      setProgressModal(prev => ({ ...prev, open: false }));
      showNotification({
        title: 'Bulk action failed',
        message: 'Failed to ' + action + ' some articles. Please try again.',
        color: 'error'
      });
      throw error; // Propagate the error for proper handling in handleBulkAction
    }
  }, [articles, batchSize, fetchArticles, showNotification]);

  const getAvailableBulkActions = useCallback(() => {
    const actions = ['delete', 'export', 'publish'];
    return actions;
  }, []);

  const handleExport = useCallback((format) => {
    const selectedArticlesList = articles.filter(article => selectedArticles.includes(article.id));
    let exportData;

    if (format === 'csv') {
      const headers = ['id', 'video_title', 'status', 'video_duration', 'total_processing_time'];
      const csvContent = [
        headers.join(','),
        ...selectedArticlesList.map(article => 
          headers.map(header => JSON.stringify(article[header])).join(',')
        )
      ].join('\n');

      exportData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    } else if (format === 'json') {
      exportData = new Blob([JSON.stringify(selectedArticlesList, null, 2)], { type: 'application/json' });
    }

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(exportData);
      link.setAttribute('href', url);
      link.setAttribute('download', `exported_articles.${format}`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setExportModalOpen(false);
    showNotification({
      title: 'Export completed',
      message: `Articles exported as ${format.toUpperCase()}`,
      color: 'success'
    });
  }, [articles, selectedArticles, showNotification]);

  const handleBulkEdit = useCallback(async (editData) => {
    const idsToProcess = selectedArticles;

    setProgressModal({
      open: true,
      progress: 0,
      total: idsToProcess.length,
      minimized: false
    });

    try {
      for (let i = 0; i < idsToProcess.length; i++) {
        await pb.collection('VideoProcessingLogs').update(idsToProcess[i], editData);
        setProgressModal(prev => ({
          ...prev,
          progress: i + 1
        }));
      }

      setProgressModal(prev => ({ ...prev, open: false }));
      fetchArticles(1);
      showNotification({
        title: 'Bulk edit completed',
        message: `Edited ${idsToProcess.length} articles`,
        color: 'success'
      });
    } catch (error) {
      console.error('Error in bulk editing:', error);
      setProgressModal(prev => ({ ...prev, open: false }));
      showNotification({
        title: 'Bulk edit failed',
        message: 'Failed to edit some articles. Please try again.',
        color: 'error'
      });
    }
  }, [selectedArticles, fetchArticles, showNotification]);

  const toggleAllArticles = useCallback(() => {
    if (selectedArticles.length === articles.length) {
      setSelectedArticles([]);
    } else {
      setSelectedArticles(articles.map(article => article.id));
    }
  }, [articles, selectedArticles]);

  const memoizedHandlers = useMemo(() => ({
    handlePageChange,
    toggleArticleSelection,
    handleView,
    handleSave,
    handleCopy,
    handleDelete,
    handleRetry,
    handlePublish,
    closePublishModal,
    handleBulkAction,
    handleExport,
    handleBulkEdit,
    getAvailableBulkActions,
    setOpenPublishModal,
    setOpenDeleteModal,
    setExportModalOpen,
    confirmDelete,
    handlePublishSuccess,
    publishToWordPress,
    setArticleToPublish,
    toggleAllArticles,
  }), [
    handlePageChange,
    toggleArticleSelection,
    handleView,
    handleSave,
    handleCopy,
    handleDelete,
    handleRetry,
    handlePublish,
    closePublishModal,
    handleBulkAction,
    handleExport,
    handleBulkEdit,
    getAvailableBulkActions,
    setOpenPublishModal,
    setOpenDeleteModal,
    setExportModalOpen,
    confirmDelete,
    handlePublishSuccess,
    publishToWordPress,
    setArticleToPublish,
    toggleAllArticles,
  ]);

  const memoizedValue = useMemo(() => ({
    articles,
    loading,
    error,
    totalPages,
    currentPage,
    fetchArticles,
    selectedArticles,
    setSelectedArticles,
    bulkAction,
    setBulkAction,
    openDeleteModal,
    setOpenDeleteModal,
    articleToDelete,
    setArticleToDelete,
    openPublishModal,
    setOpenPublishModal,
    articleToPublish,
    setArticleToPublish,
    confirmationModal,
    setConfirmationModal,
    progressModal,
    setProgressModal,
    undoAction,
    setUndoAction,
    exportModalOpen,
    setExportModalOpen,
    isLoading,
    setIsLoading,
    wpSettings,
    notification,
    showNotification,
    closeNotification,
    publishToWordPress,
    closePublishModal,
    toggleAllArticles,
    articlesToDelete,
    setArticlesToDelete,
    handleDelete,
    handleBulkDelete,
    confirmDelete,
    handlePublishSuccess,
    loadWordPressSettings,
    ...memoizedHandlers,
  }), [
    articles,
    loading,
    error,
    totalPages,
    currentPage,
    fetchArticles,
    selectedArticles,
    bulkAction,
    openDeleteModal,
    articleToDelete,
    openPublishModal,
    articleToPublish,
    confirmationModal,
    progressModal,
    undoAction,
    exportModalOpen,
    isLoading,
    wpSettings,
    notification,
    publishToWordPress,
    closePublishModal,
    toggleAllArticles,
    articlesToDelete,
    setArticlesToDelete,
    handleDelete,
    handleBulkDelete,
    confirmDelete,
    handlePublishSuccess,
    loadWordPressSettings,
    memoizedHandlers,
  ]);

  useEffect(() => {
    fetchArticles(currentPage);
  }, [fetchArticles, currentPage]);

  return <ArticleContext.Provider value={memoizedValue}>{children}</ArticleContext.Provider>;
});

export const useArticleContext = () => {
  const context = useContext(ArticleContext);
  if (!context) {
    throw new Error('useArticleContext must be used within an ArticleProvider');
  }
  return context;
};