import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../utils/api';
import { useQueryClient } from 'react-query';

function GoogleAuthCallback() {
  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleAuthResult = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
      const error = urlParams.get('error');

      if (code) {
        try {
          await api.post('/api/save-google-token', { code });
          queryClient.invalidateQueries('googleFormsStatus');
          window.opener.postMessage({ type: 'GOOGLE_AUTH_SUCCESS' }, window.location.origin);
        } catch (err) {
          console.error('Error saving Google token:', err);
          window.opener.postMessage({ type: 'GOOGLE_AUTH_ERROR', error: err.message }, window.location.origin);
        }
      } else if (error) {
        window.opener.postMessage({ type: 'GOOGLE_AUTH_ERROR', error }, window.location.origin);
      }

      window.close();
    };

    handleAuthResult();
  }, [location.search, queryClient]);

  return <div>Processing Google Authentication...</div>;
}

export default GoogleAuthCallback;