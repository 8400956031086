import React from 'react';
import { Typography, Box, Tabs, TabList, Tab, TabPanel, Divider } from '@mui/joy';
import WordPressIntegration from '../components/WordPressIntegration';
import GoogleFormsIntegration from '../components/GoogleFormsIntegration';

function Settings() {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Typography level="h2" sx={{ mb: 2 }}>Settings</Typography>
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        sx={{ width: '100%' }}
      >
        <TabList>
          <Tab>General</Tab>
          <Tab>Integrations</Tab>
        </TabList>
        <TabPanel value={0} sx={{ p: 2, width: '100%' }}>
          <Typography level="h3" component="h2">General Settings</Typography>
          {/* Add general settings here */}
        </TabPanel>
        <TabPanel value={1} sx={{ p: 2, width: '100%' }}>
          <Typography level="h3" sx={{ mb: 3 }}>Integrations</Typography>
          
          <Box sx={{ mb: 4 }}>
            <Typography level="h4" sx={{ mb: 2 }}>WordPress Integration</Typography>
            <WordPressIntegration />
          </Box>
          
          <Divider sx={{ my: 4 }} />
          
          <Box>
            <GoogleFormsIntegration />
          </Box>
        </TabPanel>
      </Tabs>
    </Box>
  );
}

export default React.memo(Settings);