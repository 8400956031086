import React from 'react';
import { Typography, Box, Select, Option, Button, Chip } from '@mui/joy';
import { useMutation, useQueryClient } from 'react-query';
import api from '../utils/api';

function DefaultPostingSettings({ connectedSite, categoriesAndTags, defaultSettings, onSaveSuccess }) {
  const queryClient = useQueryClient();

  const saveSettingsMutation = useMutation(
    (newSettings) => api.post('/api/wordpress-posting-settings', newSettings),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('wordpressSettings');
        onSaveSuccess();
      },
    }
  );

  const handleSaveSettings = () => {
    if (defaultSettings) {
      saveSettingsMutation.mutate(defaultSettings);
    }
  };

  const updateQueryData = (updater) => {
    queryClient.setQueryData('wordpressSettings', (oldData) => {
      if (!oldData || !oldData.postingSettings) return oldData;
      return {
        ...oldData,
        postingSettings: {
          ...oldData.postingSettings,
          defaultSettings: updater(oldData.postingSettings.defaultSettings)
        }
      };
    });
  };

  if (!categoriesAndTags || !defaultSettings) {
    return <Typography color="danger">Failed to load settings</Typography>;
  }

  return (
    <Box>
      <Typography level="h5" sx={{ mb: 2 }}>Default Posting Settings</Typography>
      <Box sx={{ mb: 2 }}>
        <Typography>Post Status</Typography>
        <Select
          value={defaultSettings.postStatus}
          onChange={(_, value) => updateQueryData(old => ({ ...old, postStatus: value }))}
        >
          <Option value="draft">Draft</Option>
          <Option value="publish">Publish</Option>
          <Option value="pending">Pending Review</Option>
        </Select>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>Categories</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {categoriesAndTags.categories.map((category) => (
            <Chip
              key={category.id}
              variant={defaultSettings.categories.includes(category.id) ? 'solid' : 'outlined'}
              color={defaultSettings.categories.includes(category.id) ? 'primary' : 'neutral'}
              onClick={() => {
                updateQueryData(old => ({
                  ...old,
                  categories: old.categories.includes(category.id)
                    ? old.categories.filter(id => id !== category.id)
                    : [...old.categories, category.id]
                }));
              }}
            >
              {category.name}
            </Chip>
          ))}
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>Tags</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {categoriesAndTags.tags.map((tag) => (
            <Chip
              key={tag.id}
              variant={defaultSettings.tags.includes(tag.id) ? 'solid' : 'outlined'}
              color={defaultSettings.tags.includes(tag.id) ? 'primary' : 'neutral'}
              onClick={() => {
                updateQueryData(old => ({
                  ...old,
                  tags: old.tags.includes(tag.id)
                    ? old.tags.filter(id => id !== tag.id)
                    : [...old.tags, tag.id]
                }));
              }}
            >
              {tag.name}
            </Chip>
          ))}
        </Box>
      </Box>
      <Button onClick={handleSaveSettings} disabled={saveSettingsMutation.isLoading}>
        {saveSettingsMutation.isLoading ? 'Saving...' : 'Save Default Settings'}
      </Button>
    </Box>
  );
}

export default DefaultPostingSettings;