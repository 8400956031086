import React, { useMemo } from 'react';
import { Box, Select, Option, Button, Checkbox } from '@mui/joy';
import { useArticleContext } from '../contexts/ArticleContext';

const BulkActions = React.memo(() => {
  const { 
    selectedArticles, 
    handleBulkAction, 
    getAvailableBulkActions,
    setOpenPublishModal,
    articles,
    setArticleToPublish,
    toggleAllArticles
  } = useArticleContext();

  const [action, setAction] = React.useState('');

  const handleActionChange = React.useCallback((event, newValue) => {
    setAction(newValue);
  }, []);

  const handleApply = React.useCallback(() => {
    if (action) {
      if (action === 'publish') {
        const selectedArticlesToPublish = articles.filter(article => selectedArticles.includes(article.id));
        setArticleToPublish(selectedArticlesToPublish);
        setOpenPublishModal(true);
      } else {
        handleBulkAction(action);
      }
      setAction('');
    }
  }, [action, handleBulkAction, setOpenPublishModal, articles, selectedArticles, setArticleToPublish]);

  const availableActions = useMemo(() => getAvailableBulkActions(), [getAvailableBulkActions]);

  const allSelected = selectedArticles.length === articles.length;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, flexWrap: 'wrap', gap: 2 }}>
      <Select
        placeholder="Bulk Actions"
        value={action}
        onChange={handleActionChange}
        sx={{ minWidth: 200 }}
      >
        {availableActions.includes('delete') && <Option value="delete">Delete</Option>}
        {availableActions.includes('publish') && <Option value="publish">Publish to WordPress</Option>}
      </Select>
      <Button 
        onClick={handleApply} 
        disabled={!action || selectedArticles.length === 0}
      >
        Apply to {selectedArticles.length} selected
      </Button>
    </Box>
  );
});

export default BulkActions;