import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

import Login from './pages/Login';
import CreateContent from './pages/CreateContent';
import MyArticles from './pages/MyArticles';
import Account from './pages/Account';
import ViewArticle from './pages/ViewArticle';
import Dashboard from './components/Dashboard';
import Settings from './pages/Settings';
import GoogleAuthCallback from './components/GoogleAuthCallback';
import { GoogleFormsProvider } from './contexts/GoogleFormsContext';
import { QueryClient, QueryClientProvider } from 'react-query';

import './App.css';

const GOOGLE_MAIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_MAIN_CLIENT_ID;

function RequireAuth({ children }) {
  const isAuthenticated = !!localStorage.getItem('pocketbase_auth');
  
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider>
       <GoogleOAuthProvider clientId={GOOGLE_MAIN_CLIENT_ID}>
          <GoogleFormsProvider>
            <CssBaseline />
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/view-article/:id" element={<ViewArticle />} />
                <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>}>
                  <Route index element={<CreateContent />} />
                  <Route path="my-articles" element={<MyArticles />} />
                  <Route path="account" element={<Account />} />
                  <Route path="settings" element={<RequireAuth><Settings /></RequireAuth>} />
                </Route>
                <Route path="/google-auth-callback" element={<GoogleAuthCallback />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Router>
          </GoogleFormsProvider>
        </GoogleOAuthProvider>
      </CssVarsProvider>
    </QueryClientProvider>
  );
}

export default App;