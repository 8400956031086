import React, { useMemo } from 'react';
import { Typography, Box, Select, Option, Chip, Button, LinearProgress } from '@mui/joy';
import FadeAlert from './FadeAlert';

const PublishModalContent = React.memo(({ 
  wpSettings, 
  postStatus, 
  setPostStatus, 
  selectedCategories, 
  setSelectedCategories, 
  selectedTags, 
  setSelectedTags, 
  isPublishing, 
  error, 
  setError,
  publishProgress, 
  totalArticles, 
  articles, 
  onClose, 
  handlePublishClick 
}) => {
  const content = useMemo(() => {
    const defaultPostStatus = wpSettings?.postingSettings?.defaultSettings?.postStatus || 'draft';
    const availableCategories = wpSettings?.postingSettings?.categoriesAndTags?.categories || [];
    const availableTags = wpSettings?.postingSettings?.categoriesAndTags?.tags || [];

    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Box sx={{ flexGrow: 1, overflow: 'auto', px: 2, py: 1 }}>
          <Typography level="h4">Publish to WordPress</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography>Post Status</Typography>
            <Select value={postStatus} onChange={(_, value) => setPostStatus(value)}>
              <Option value="draft">Draft</Option>
              <Option value="publish">Publish</Option>
              <Option value="pending">Pending Review</Option>
            </Select>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Categories</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
              {availableCategories.map((category) => (
                <Chip
                  key={category.id}
                  variant={selectedCategories.includes(category.id) ? 'solid' : 'outlined'}
                  color={selectedCategories.includes(category.id) ? 'primary' : 'neutral'}
                  onClick={() => {
                    setSelectedCategories(prev => 
                      prev.includes(category.id) 
                        ? prev.filter(id => id !== category.id)
                        : [...prev, category.id]
                    )
                  }}
                >
                  {category.name}
                </Chip>
              ))}
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Tags</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
              {availableTags.map((tag) => (
                <Chip
                  key={tag.id}
                  variant={selectedTags.includes(tag.id) ? 'solid' : 'outlined'}
                  color={selectedTags.includes(tag.id) ? 'primary' : 'neutral'}
                  onClick={() => {
                    setSelectedTags(prev => 
                      prev.includes(tag.id) 
                        ? prev.filter(id => id !== tag.id)
                        : [...prev, tag.id]
                    )
                  }}
                >
                  {tag.name}
                </Chip>
              ))}
            </Box>
          </Box>
          {error && <FadeAlert message={error} severity="error" onClose={() => setError(null)} />}
        </Box>
        <Box sx={{ mt: 'auto', px: 2, py: 1 }}>
          {isPublishing && (
            <Box sx={{ width: '100%', mb: 2 }}>
              <Typography>Now publishing {publishProgress}/{totalArticles} article(s) to WordPress</Typography>
              <LinearProgress 
                determinate 
                value={(publishProgress / totalArticles) * 100} 
                sx={{ mt: 1 }}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={onClose} color="neutral">Cancel</Button>
            <Button onClick={handlePublishClick} loading={isPublishing}>
              Publish {articles.length > 1 ? `${articles.length} Articles` : 'Article'}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }, [wpSettings, postStatus, selectedCategories, selectedTags, isPublishing, error, publishProgress, totalArticles, articles]);

  return content;
});

export default PublishModalContent;