import React, { useMemo, useCallback } from 'react';
import Typography from '@mui/joy/Typography';
import ButtonGroup from '@mui/joy/ButtonGroup';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';
import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import LinearProgress from '@mui/joy/LinearProgress';
import { VisibilityRounded, ContentCopyRounded, DeleteRounded, RefreshRounded, PublishRounded, QuizRounded } from '@mui/icons-material';
import { useArticleContext } from '../contexts/ArticleContext';
import { Checkbox } from '@mui/joy';

const ArticleItem = React.memo(({ article }) => {
  const {
    selectedArticles,
    toggleArticleSelection,
    handleCopy,
    handleDelete,
    handleRetry,
    handlePublish,
    handleView,
    publishToWordPress,
    setOpenPublishModal,
    setArticleToPublish
  } = useArticleContext();

  const onHandleView = useCallback(() => {
    handleView(article.id);
  }, [handleView, article.id]);

  const isSelected = useMemo(() => selectedArticles.includes(article.id), [selectedArticles, article.id]);

  const formatDuration = useCallback((seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }, []);

  const formattedVideoDuration = useMemo(() => formatDuration(article.video_duration), [formatDuration, article.video_duration]);
  const formattedProcessTime = useMemo(() => formatDuration(article.total_processing_time), [formatDuration, article.total_processing_time]);

  const handlePublishToWordPress = () => {
    if (article && article.id) {
      setArticleToPublish(article);
      setOpenPublishModal(true);
    } else {
      console.error('Invalid article data:', article);
    }
  };

  const handleOpenQuiz = useCallback(() => {
    if (article.quiz_link) {
      window.open(article.quiz_link, '_blank');
    }
  }, [article.quiz_link]);

  // Add this log to debug the status
  console.log('Article status:', article.status);

  return (
    <tr>
      <td>
        <Checkbox
          checked={isSelected}
          onChange={() => toggleArticleSelection(article.id)}
        />
      </td>
      <td>{article.video_title}</td>
      <td>
        {article.video_url && (
          article.video_url.startsWith('https://youtu.be/') ||
          article.video_url.startsWith('https://www.youtube.com/') ||
          article.video_url.startsWith('https://youtube.com/')
        ) ? (
          <Link href={article.video_url} target="_blank" rel="noopener noreferrer nofollow" color="primary">
            {article.video_url}
          </Link>
        ) : (
          article.video_url
        )}
      </td>
      <td>
        {article.status === 'processing' ? (
          <Box>
            <Typography>Processing ({Math.round(Math.max(5, article.progress || 0))}%)</Typography>
            <LinearProgress 
              variant="determinate" 
              value={Math.max(5, article.progress || 0)} 
              sx={{ mt: 1 }}
            />
          </Box>
        ) : (
          <Typography color={article.status === 'completed' ? 'success' : 'error'}>
            {article.status}
          </Typography>
        )}
      </td>
      <td>{formattedVideoDuration}</td>
      <td>{formattedProcessTime}</td>
      <td>
        {article.status && article.status.toLowerCase() === 'completed' ? (
          <ButtonGroup variant="soft" aria-label="article actions">
            <Tooltip arrow title="View Article">
            <IconButton onClick={onHandleView}>
                <VisibilityRounded />
              </IconButton>
            </Tooltip>
            {article.quiz_link && (
              <Tooltip arrow title="Open Quiz">
                <IconButton onClick={handleOpenQuiz}>
                  <QuizRounded />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip arrow title="Copy Article">
              <IconButton onClick={() => handleCopy(article.html_content)}>
                <ContentCopyRounded />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Publish to WordPress">
              <IconButton onClick={handlePublishToWordPress}>
                <PublishRounded />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Delete Article">
              <IconButton color="danger" onClick={() => handleDelete(article.id)}>
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        ) : article.status && article.status.toLowerCase() === 'failed' ? (
          <ButtonGroup variant="soft" aria-label="article actions">
            <Tooltip arrow title="Retry">
              <IconButton onClick={() => handleRetry(article.id)}>
                <RefreshRounded />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Delete Article">
              <IconButton color="danger" onClick={() => handleDelete(article.id)}>
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        ) : (
          <Typography>Actions available when processing completes</Typography>
        )}
      </td>
    </tr>
  );
});

export default ArticleItem;