import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import CircularProgress from '@mui/joy/CircularProgress';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import AspectRatio from '@mui/joy/AspectRatio';
import Input from '@mui/joy/Input';
import { ContentCopyRounded, Twitter, Facebook, LinkedIn, Quiz as QuizIcon } from '@mui/icons-material';
import PocketBase from 'pocketbase';
import { ArrowLeft, ArrowRight, RotateCcw, Minus, Square, X } from 'lucide-react';
import Stack from '@mui/joy/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Notification from '../components/Notification';
import { styled } from '@mui/system';

// Add this styled component
const ResponsiveImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
  display: 'block',
  margin: '0 auto',
});

function ViewArticle() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const fetchArticle = useCallback(async () => {
    try {
      const pb = new PocketBase(process.env.REACT_APP_PB_URL || 'https://log.8motions.com');
      
      // Try to get the article without authentication
      const record = await pb.collection('VideoProcessingLogs').getOne(id);
      
      setArticle(record);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching article:', err);
      setError('Failed to fetch article');
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchArticle();
  }, [fetchArticle]);

  const handleCopy = async () => {
    try {
      const contentElement = document.createElement('div');
      contentElement.innerHTML = article.html_content;

      // Extract text content
      const textContent = contentElement.innerText;

      // Extract image URLs
      const imageUrls = Array.from(contentElement.querySelectorAll('img'))
        .map(img => img.src);

      // Combine text and images into a single string
      const combinedContent = `${textContent}\n\n${imageUrls.join('\n')}`;

      // Create a blob with HTML content
      const htmlBlob = new Blob([article.html_content], { type: 'text/html' });

      // Create a blob with plain text content
      const textBlob = new Blob([combinedContent], { type: 'text/plain' });

      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': htmlBlob,
          'text/plain': textBlob,
        }),
      ]);

      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } catch (err) {
      console.error('Failed to copy content: ', err);
      alert('Failed to copy content');
    }
  };

  const handleShare = (platform) => {
    const url = window.location.href;
    const text = `Check out this article: ${article.video_title}`;
    let shareUrl;

    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(article.video_title)}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  };

  const handleCardClick = () => {
    window.open('https://8motions.com', '_blank');
  };

  // Use this hook to determine if the screen is desktop-sized
  const isDesktop = useMediaQuery('(min-width:1200px)');

  // Add this function to process the HTML content
  const processHtmlContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    
    // Replace all img tags with our ResponsiveImage component
    doc.querySelectorAll('img').forEach(img => {
      img.outerHTML = `<img class="responsive-image" src="${img.src}" alt="${img.alt || ''}" />`;
    });

    return doc.body.innerHTML;
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="danger">{error}</Typography>;
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        p: 2,
        bgcolor: 'background.level1',
        position: 'relative',
      }}
    >
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', sm: '600px', md: '900px' },
            mb: { xs: 2, lg: 0 },
          }}
        >
          <Box className="relative">
            <Box className="absolute inset-0 bg-black transform -translate-x-2 -translate-y-2 rounded-lg"></Box>
            
            <Box className="relative bg-purple-100 rounded-lg overflow-hidden border-2 border-black">
              <Box className="bg-gray-100 p-2 flex items-center space-x-4 border-b-2 border-black">
                <Box className="flex space-x-2">
                  <IconButton variant="plain" color="neutral"><ArrowLeft size={14} /></IconButton>
                  <IconButton variant="plain" color="neutral"><ArrowRight size={14} /></IconButton>
                  <IconButton variant="plain" color="neutral"><RotateCcw size={14} /></IconButton>
                </Box>
                <Input
                  className="flex-1"
                  size="sm"
                  value="https://8motions.com"
                  readOnly
                  sx={{ pr: '5px' }}
                />
                <Box 
                  sx={{ 
                    display: 'flex', 
                    gap: 0.5, 
                    borderRadius: 'sm',
                    p: 0.5
                  }}
                >
                  {[Minus, Square, X].map((Icon, index) => (
                    <IconButton
                      key={index}
                      variant="plain"
                      color="neutral"
                      sx={{ 
                        p: 0.25,
                        '&:hover': { bgcolor: 'background.level1' }
                      }}
                    >
                      <Icon size={14} />
                    </IconButton>
                  ))}
                </Box>
              </Box>

              <Box className="p-6 bg-white">
                <Typography level="h4" className="mb-2">Source: {article.video_title}</Typography>
                
                <Box className="generated-content mt-4">
                  <Box 
                    dangerouslySetInnerHTML={{ 
                      __html: processHtmlContent(article.html_content) 
                    }} 
                    sx={{
                      '& .responsive-image': {
                        maxWidth: '100%',
                        height: 'auto',
                        display: 'block',
                        margin: '0 auto',
                      },
                      '& figure': {
                        margin: '1em 0',
                        textAlign: 'center',
                      },
                      '& figcaption': {
                        marginTop: '0.5em',
                        fontSize: '0.9em',
                        color: 'text.secondary',
                      },
                    }}
                  />
                  
                  {article.quiz_link && (
                    <Box sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center' }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startDecorator={<QuizIcon />}
                        component="a"
                        href={article.quiz_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Take the Quiz
                      </Button>
                    </Box>
                  )}

                  <Box className="button-group mt-4 flex justify-center items-center space-x-2">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCopy}
                      startDecorator={<ContentCopyRounded />}
                    >
                      Copy
                    </Button>
                    <Typography level="body-md">Share on</Typography>
                    <IconButton
                      variant="outlined"
                      color="primary"
                      onClick={() => handleShare('twitter')}
                    >
                      <Twitter />
                    </IconButton>
                    <IconButton
                      variant="outlined"
                      color="primary"
                      onClick={() => handleShare('facebook')}
                    >
                      <Facebook />
                    </IconButton>
                    <IconButton
                      variant="outlined"
                      color="primary"
                      onClick={() => handleShare('linkedin')}
                    >
                      <LinkedIn />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <Box className="p-4 bg-purple-100">
                <Typography level="body-sm" className="text-center">
                  This page was generated by <a href="https://8motions.com" target="_blank" rel="noopener noreferrer">8motions</a>. 
                  Transforming YouTube videos into engaging articles - Discover a new way to consume content!
                </Typography>
                {article.quiz_link && (
                  <Typography level="body-sm" className="text-center mt-2">
                    Test your knowledge: <a href={article.quiz_link} target="_blank" rel="noopener noreferrer">Take the quiz</a>
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        {!isDesktop && (
          <Card
            variant="outlined"
            sx={{
              width: { xs: '100%', sm: '600px' },
              maxWidth: '100%',
              cursor: 'pointer',
              '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
            }}
            onClick={handleCardClick}
          >
            <AspectRatio ratio="1" sx={{ width: 50, margin: '0 auto' }}>
              <Box sx={{ bgcolor: 'background.level2', borderRadius: '50%' }}>
                {/* Placeholder for logo */}
              </Box>
            </AspectRatio>
            <CardContent>
              <Typography level="title-md" textAlign="center">
                Turn any YouTube video into an article
              </Typography>
              <Typography level="body-sm" textAlign="center" mt={1}>
                Try it for free
              </Typography>
              <Input
                placeholder="Enter YouTube URL"
                sx={{ mt: 2, pointerEvents: 'none' }}
              />
            </CardContent>
          </Card>
        )}
      </Stack>

      {isDesktop && (
        <Card
          variant="outlined"
          sx={{
            position: 'fixed',
            bottom: 10,
            right: 10,
            width: 250,
            cursor: 'pointer',
            '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
          }}
          onClick={handleCardClick}
        >
          <AspectRatio ratio="1" sx={{ width: 50, margin: '0 auto' }}>
            <Box sx={{ bgcolor: 'background.level2', borderRadius: '50%' }}>
              {/* Placeholder for logo */}
            </Box>
          </AspectRatio>
          <CardContent>
            <Typography level="title-md" textAlign="center">
              Turn any YouTube video into an article
            </Typography>
            <Typography level="body-sm" textAlign="center" mt={1}>
              Try it for free
            </Typography>
            <Input
              placeholder="Enter YouTube URL"
              sx={{ mt: 2, pointerEvents: 'none' }}
            />
          </CardContent>
        </Card>
      )}
      
      {showNotification && (
        <Notification 
          onClose={() => setShowNotification(false)} 
          title="Copied to Clipboard"
          message="The content has been copied to your clipboard."
        />
      )}
    </Box>
  );
}

export default ViewArticle;