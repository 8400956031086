import React from 'react';
import { Button, Typography, Box } from '@mui/joy';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';

const pb = new PocketBase(process.env.REACT_APP_PB_URL || 'https://log.8motions.com');

function Login() {
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      const authData = await pb.collection('users').authWithOAuth2({ provider: 'google' });
      handleAuthSuccess(authData);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const handleAuthSuccess = (authData) => {
    console.log('PocketBase auth data:', authData);
    localStorage.setItem('pocketbase_auth', JSON.stringify(authData));
    localStorage.setItem('pocketbase_token', authData.token);
    navigate('/'); // This will redirect to the root path
  };

  return (
    <Box className="min-h-screen bg-purple-100 flex items-center justify-center p-4">
      <Box className="w-full max-w-3xl relative">
        <Box className="absolute inset-0 bg-black transform -translate-x-2 -translate-y-2 rounded-lg"></Box>
        
        <Box className="relative bg-purple-100 rounded-lg overflow-hidden border-2 border-black">
          <Box className="bg-gray-100 p-2 flex items-center space-x-2 border-b-2 border-black">
            <Box className="flex space-x-2">
              <Box className="w-3 h-3 rounded-full bg-gray-300"></Box>
              <Box className="w-3 h-3 rounded-full bg-gray-300"></Box>
              <Box className="w-3 h-3 rounded-full bg-gray-300"></Box>
            </Box>
            <Box className="flex-1 bg-white rounded px-2 py-1 text-sm text-gray-600 border border-gray-300">
              https://8motions.com/login
            </Box>
          </Box>

          <Box className="p-6 bg-purple-100">
            <Typography level="h4" className="mb-8">8motions - Turn any YouTube video into a blog post in a minute. Now integrated with your WordPress!</Typography>
            
            <Box className="text-center">
              <Box className="bg-white p-4 rounded-lg shadow-md">
                <Typography level="body1" className="mb-6">Please log in to continue</Typography>
                <Button
                  variant="outlined"
                  color="neutral"
                  fullWidth
                  onClick={handleGoogleLogin}
                  startDecorator={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                      <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                      <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                      <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                      <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
                    </svg>
                  }
                  sx={{ mb: 2 }}
                >
                  Sign in with Google
                </Button>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleFacebookLogin}
                  startDecorator={<Facebook />}
                >
                  Sign in with Facebook
                </Button> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;