import React from 'react';
import { Box, Typography, Skeleton } from '@mui/joy';

const DefaultPostingSettingsSkeleton = () => {
  return (
    <Box>
      <Typography level="h5" sx={{ mb: 2 }}>
        <Skeleton variant="text" width={200} />
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography>
          <Skeleton variant="text" width={100} />
        </Typography>
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>
          <Skeleton variant="text" width={100} />
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {[...Array(5)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" width={80} height={32} />
          ))}
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>
          <Skeleton variant="text" width={100} />
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {[...Array(5)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" width={80} height={32} />
          ))}
        </Box>
      </Box>
      <Skeleton variant="rectangular" width={200} height={40} />
    </Box>
  );
};

export default DefaultPostingSettingsSkeleton;