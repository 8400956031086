import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Modal, ModalDialog, Typography, Box, CircularProgress, Button } from '@mui/joy';
import { useArticleContext } from '../contexts/ArticleContext';
import PublishModalContent from './PublishModalContent';

const PublishToWordPress = React.memo(({ open, onClose, articles }) => {
  const { 
    publishToWordPress, 
    wpSettings, 
    loadWordPressSettings 
  } = useArticleContext();

  const [postStatus, setPostStatus] = useState('draft');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [publishProgress, setPublishProgress] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);

  useEffect(() => {
    if (open) {
      const articlesCount = Array.isArray(articles) ? articles.length : (articles ? 1 : 0);
      setTotalArticles(articlesCount);
      setPublishProgress(0);
      setIsLoading(true);
      setError(null);
      loadWordPressSettings().then((result) => {
        console.log('WordPress settings loaded:', result);
        if (!result.data || !result.data.isConnected) {
          console.error('No WordPress connection found in loaded settings');
          setError('No WordPress connection found. Please connect your WordPress in Settings.');
        } else {
          console.log('WordPress connection found:', result.data);
        }
      }).catch((error) => {
        console.error('Error loading WordPress settings:', error);
        setError('Failed to load WordPress settings. Please try again.');
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [open, articles, loadWordPressSettings]);

  useEffect(() => {
    if (wpSettings) {
      setPostStatus(wpSettings.postingSettings?.defaultSettings?.postStatus || 'draft');
      setSelectedCategories(wpSettings.postingSettings?.defaultSettings?.categories || []);
      setSelectedTags(wpSettings.postingSettings?.defaultSettings?.tags || []);
    }
  }, [wpSettings]);

  const handlePublishClick = useCallback(async () => {
    setIsPublishing(true);
    setError(null);
    setPublishProgress(0);
    try {
      if (!Array.isArray(articles) || articles.length === 0) {
        throw new Error('No articles selected for publishing');
      }
      await publishToWordPress(articles, { postStatus, categories: selectedCategories, tags: selectedTags }, setPublishProgress);
      onClose();
    } catch (error) {
      setError(`Failed to publish articles: ${error.message}`);
    } finally {
      setIsPublishing(false);
    }
  }, [articles, publishToWordPress, postStatus, selectedCategories, selectedTags, onClose]);

  const modalContent = useMemo(() => {
    return (
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          sx={{
            maxWidth: '400px',
            width: '100%',
            maxHeight: '90vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isLoading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <CircularProgress />
              <Typography level="body-sm" sx={{ mt: 1 }}>
                Loading WordPress settings...
              </Typography>
            </Box>
          ) : error ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Typography color="danger">{error}</Typography>
              <Button onClick={onClose} sx={{ mt: 2 }}>Close</Button>
            </Box>
          ) : (
            <PublishModalContent 
              wpSettings={wpSettings}
              postStatus={postStatus}
              setPostStatus={setPostStatus}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              isPublishing={isPublishing}
              error={error}
              setError={setError}
              publishProgress={publishProgress}
              totalArticles={totalArticles}
              articles={articles}
              onClose={onClose}
              handlePublishClick={handlePublishClick}
            />
          )}
        </ModalDialog>
      </Modal>
    );
  }, [isLoading, error, wpSettings, open, onClose, postStatus, selectedCategories, selectedTags, isPublishing, publishProgress, totalArticles, articles, handlePublishClick]);

  return modalContent;
});

export default PublishToWordPress;