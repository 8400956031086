import React, { useState, useEffect } from 'react';
import { Box, Typography, Input, Select, Option, Sheet, IconButton, Grid, Switch, Link, CircularProgress, LinearProgress, Slider, Accordion, AccordionSummary, AccordionDetails, Chip, Checkbox, Tooltip, Alert } from '@mui/joy';
import { ArrowLeft, ArrowRight, RotateCcw, Video, FileText, Upload, X, ChevronDown, Check, BookOpen, HelpCircle, AlertCircle, Lightbulb } from 'lucide-react';
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import { handleGenerate, checkJobStatus, checkGoogleTokenStatus, uploadFileInChunks, generateRelatedKeywords, getYouTubeVideoInfo } from '../utils/api';
import Notification from '../components/Notification';
import { keyframes } from '@emotion/react';

function CreateContent() {
  const [url, setUrl] = useState('');
  const [videoLanguage, setVideoLanguage] = useState('');
  const [blogLanguage, setBlogLanguage] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [jobId, setJobId] = useState(null);
  const [showProcessingNotification, setShowProcessingNotification] = useState(false);
  const [generateQuiz, setGenerateQuiz] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputSource, setInputSource] = useState(null);
  const [inputType, setInputType] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [activeInput, setActiveInput] = useState('url');
  const [targetKeywords, setTargetKeywords] = useState('');
  const [generatedKeywords, setGeneratedKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isGeneratingKeywords, setIsGeneratingKeywords] = useState(false);
  const [targetImagesCount, setTargetImagesCount] = useState(5);
  const [showAdvancedFeatures, setShowAdvancedFeatures] = useState(false);
  const [hasKeywords, setHasKeywords] = useState(false);
  const [showLanguageAlert, setShowLanguageAlert] = useState(false);
  const [keywordsConfirmed, setKeywordsConfirmed] = useState(false);
  const [showGeneratingAlert, setShowGeneratingAlert] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);
  const [showDurationAlert, setShowDurationAlert] = useState(false);

  const MAX_FILE_SIZE = 500 * 1024 * 1024; // 500MB in bytes

  const pulseAnimation = keyframes`
    0% {
      box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
    }
  `;

  useEffect(() => {
    if (blogLanguage) {
      setShowLanguageAlert(false);
    }
  }, [blogLanguage]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError("File size exceeds 500MB limit. Please choose a smaller file.");
        setVideoFile(null);
      } else {
        setInputSource(file);
        setInputType('file');
        setVideoTitle(file.name);
        setVideoFile(file);
        setUrl('');
        setError(null);
        setActiveInput('file');
      }
    }
  };

  const handleUrlChange = async (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setShowDurationAlert(false); // Reset alert when URL changes

    if (newUrl.trim() !== '') {
      setInputSource(newUrl);
      setInputType('url');
      setActiveInput('url');
      try {
        const videoInfo = await getYouTubeVideoInfo(newUrl);
        setVideoThumbnail(videoInfo.thumbnail);
        setVideoDuration(videoInfo.duration);
        setVideoTitle(videoInfo.title);

        // Check duration and show alert if necessary
        if (videoInfo.duration > 2400) { // 2400 seconds = 40 minutes
          setShowDurationAlert(true);
        }
      } catch (error) {
        console.error('Error fetching video info:', error);
        // Handle error (e.g., show a message to the user)
      }
    } else {
      setInputSource(null);
      setInputType('');
      setVideoThumbnail(null);
      setVideoDuration(null);
      setVideoTitle('');
    }
    setVideoFile(null);
  };

  const validateForm = () => {
    const errors = {};
    if (!inputSource) {
      errors.video = "Please enter a YouTube URL or upload a video file";
    }
    if (!videoLanguage) {
      errors.videoLanguage = "Please choose a video language";
    }
    if (!blogLanguage) {
      errors.blogLanguage = "Please choose a blog language";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onGenerate = async () => {
    if (!validateForm()) {
      return;
    }

    if (!videoLanguage || !blogLanguage) {
      setError("Please select both video and blog languages");
      return;
    }

    // Check video duration if it's a YouTube URL
    if (inputType === 'url' && videoDuration > 2400) {
      setError("Video exceeds the 40-minute limit. Please use a shorter video.");
      setShowDurationAlert(true);
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    setError(null);

    try {
      if (generateQuiz) {
        const tokenStatus = await checkGoogleTokenStatus();
        console.log('Token status:', tokenStatus);
        if (!tokenStatus.isConnected || !tokenStatus.isTokenValid) {
          showGoogleTokenInvalidModal();
          setIsGenerating(false);
          setIsLoading(false);
          return;
        }
      }

      console.log('Generating with quiz option:', generateQuiz);
      let jobId;
      if (inputType === 'file') {
        const { jobId, recordId, uploadDuration } = await uploadFileInChunks(
          inputSource, 
          videoLanguage, 
          blogLanguage, 
          generateQuiz, 
          selectedKeywords.join(', '), 
          targetImagesCount, 
          (progress) => setUploadProgress(progress)
        );
        console.log(`File uploaded in ${uploadDuration} seconds`);
        setJobId(jobId);
      } else if (inputType === 'url') {
        jobId = await handleGenerate(inputSource, videoLanguage, blogLanguage, generateQuiz, selectedKeywords.join(', '), targetImagesCount);
      } else {
        throw new Error('Invalid input type');
      }
      
      console.log('Job data received:', jobId);
      setShowProcessingNotification(true);
      setTimeout(() => setShowProcessingNotification(false), 4000);
      setUrl('');
      setVideoFile(null);
      setUploadProgress(0);
    } catch (error) {
      console.error('Error during generation:', error);
      setError(error.message || 'An unknown error occurred');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  const showGoogleTokenInvalidModal = () => {
    setShowNotification(true);
  };

  useEffect(() => {
    let intervalId;

    if (jobId && typeof jobId === 'string') {
      intervalId = setInterval(async () => {
        try {
          const status = await checkJobStatus(jobId);
          if (status.status === 'finished') {
            setIsGenerating(false);
            clearInterval(intervalId);
          } else if (status.status === 'failed') {
            setError('Job processing failed');
            setIsGenerating(false);
            clearInterval(intervalId);
          }
        } catch (error) {
          setError(error.message);
          setIsGenerating(false);
          clearInterval(intervalId);
        }
      }, 5000); // Check every 5 seconds
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [jobId]);

  const removeFile = () => {
    setVideoFile(null);
    setVideoTitle('');
    setInputSource(null);
    setInputType('');
    setActiveInput('url');
    // Reset the file input
    const fileInput = document.getElementById('raised-button-file');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleKeywordChange = (e) => {
    const value = e.target.value;
    setTargetKeywords(value);
    setHasKeywords(value.trim().length > 0);
    setKeywordsConfirmed(false);
  };

  const handleKeywordFocus = () => {
    if (!blogLanguage) {
      setShowLanguageAlert(true);
    }
  };

  const handleConfirmKeywords = async () => {
    if (!blogLanguage) {
      setShowLanguageAlert(true);
      return;
    }
  
    if (!targetKeywords.trim()) {
      setError("Please enter at least one keyword before confirming.");
      return;
    }
  
    setIsGeneratingKeywords(true);
    setKeywordsConfirmed(true);
    setShowGeneratingAlert(true);
    
    try {
      const manualKeywords = targetKeywords.split(',').map(k => k.trim()).filter(k => k);
      setSelectedKeywords(manualKeywords);
  
      const response = await generateRelatedKeywords(targetKeywords, blogLanguage);
      setGeneratedKeywords(response.keywords);
      setShowGeneratingAlert(false);
    } catch (error) {
      console.error('Error generating keywords:', error);
      setError("Failed to generate related keywords. Please try again.");
      setShowGeneratingAlert(false);
    } finally {
      setIsGeneratingKeywords(false);
    }
  };

  const handleKeywordToggle = (keyword) => {
    setSelectedKeywords(prev => 
      prev.includes(keyword) 
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword]
    );
  };

  return (
    <Box className="min-h-screen bg-purple-100 flex items-center justify-center p-4">
      <Box className="w-full max-w-3xl relative">
        <Box className="absolute inset-0 bg-black transform -translate-x-2 -translate-y-2 rounded-lg"></Box>
        
        <Box className="relative bg-purple-100 rounded-lg overflow-hidden border-2 border-black">
          <Box className="bg-gray-100 p-2 flex items-center space-x-4 border-b-2 border-black">
            <Box className="flex space-x-4">
              <IconButton variant="plain" color="neutral"><ArrowLeft size={14} /></IconButton>
              <IconButton variant="plain" color="neutral"><ArrowRight size={14} /></IconButton>
              <IconButton variant="plain" color="neutral"><RotateCcw size={14} /></IconButton>
            </Box>
            <Input
              className="flex-1"
              size="sm"
              value="https://8motions.com"
              readOnly
            />
            <Box className="flex space-x-2">
              <Box className="w-3 h-3 rounded-full bg-gray-300"></Box>
              <Box className="w-3 h-3 rounded-full bg-gray-300"></Box>
              <Box className="w-3 h-3 rounded-full bg-gray-300"></Box>
            </Box>
          </Box>

          <Box className="p-6 bg-purple-100">
            <Typography level="h4" className="mb-8">8motions: Video to Blog Post Generator</Typography>
            
            <Sheet className="p-4 rounded-lg">
              {showNotification && (
                <Notification
                  onClose={() => setShowNotification(false)}
                  title="Google Account Reconnection Required"
                  message={
                    <span>
                      Your Google account needs to be reconnected to use the quiz generation feature. 
                      Please visit the <Link href="/settings" color="primary">Settings page</Link> to reconnect.
                    </span>
                  }
                />
              )}
              {showProcessingNotification && (
                <Notification
                  onClose={() => setShowProcessingNotification(false)}
                  title="Processing! 🏃💨"
                  message="A 10-minute video typically takes 1-2 minutes. Please check 'My Articles' page later."
                />
              )}
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={12}>
                  <Typography level="body2" className="mb-2">Enter YouTube URL or Upload Video:</Typography>
                  <Box display="flex" alignItems="center">
                    <Input
                      fullWidth
                      value={url}
                      onChange={handleUrlChange}
                      placeholder="https://www.youtube.com/watch?v=..."
                      error={!!formErrors.video || showDurationAlert}
                      sx={{ 
                        flexGrow: 1, 
                        marginRight: 2,
                        opacity: activeInput === 'file' ? 0.5 : 1,
                        pointerEvents: activeInput === 'file' ? 'none' : 'auto'
                      }}
                      startDecorator={<Video />}
                    />
                    <input
                      accept="video/*"
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="raised-button-file">
                      <IconButton 
                        component="span" 
                        variant={activeInput === 'file' ? 'solid' : 'outlined'} 
                        color={activeInput === 'file' ? 'primary' : 'neutral'}
                      >
                        <Upload />
                      </IconButton>
                    </label>
                  </Box>
                  {videoFile && (
                    <Box sx={{ 
                      mt: 2, 
                      p: 2, 
                      bgcolor: 'background.level1', 
                      borderRadius: 'sm', 
                      display: 'flex', 
                      alignItems: 'center',
                      maxWidth: '100%',
                      overflow: 'hidden'
                    }}>
                      <Box sx={{ 
                        width: 40, 
                        height: 40, 
                        bgcolor: 'background.level2', 
                        borderRadius: 'sm', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        mr: 2
                      }}>
                        <Video size={24} />
                      </Box>
                      <Typography 
                        level="body2" 
                        color="primary" 
                        fontWeight="bold"
                        sx={{ 
                          flexGrow: 1, 
                          overflow: 'hidden', 
                          textOverflow: 'ellipsis', 
                          whiteSpace: 'nowrap' 
                        }}
                      >
                        {videoTitle}
                      </Typography>
                      <IconButton 
                        onClick={removeFile} 
                        size="sm" 
                        variant="plain" 
                        color="neutral"
                        sx={{ ml: 1 }}
                      >
                        <X size={18} />
                      </IconButton>
                    </Box>
                  )}
                  {videoThumbnail && (
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                      <img src={videoThumbnail} alt="Video thumbnail" style={{ width: 120, height: 'auto', marginRight: 16 }} />
                      <Typography level="body2">{videoTitle}</Typography>
                    </Box>
                  )}
                  {videoDuration && (
                    <Typography level="body2" sx={{ mt: 1 }}>
                      Duration: {Math.floor(videoDuration / 60)}:{(videoDuration % 60).toString().padStart(2, '0')}
                    </Typography>
                  )}
                  {showDurationAlert && (
                    <Alert
                      color="warning"
                      startDecorator={<AlertCircle />}
                      sx={{ mb: 2 }}
                      onClose={() => setShowDurationAlert(false)}
                    >
                      This video exceeds the 40-minute limit. Please choose a shorter video for processing.
                    </Alert>
                  )}
                  {formErrors.video && <Typography color="danger" level="body3">{formErrors.video}</Typography>}
                </Grid>
                <Grid xs={6}>
                  <Typography level="body2" className="mb-2">Choose video language:</Typography>
                  <Select
                    fullWidth
                    value={videoLanguage}
                    onChange={(_, value) => setVideoLanguage(value)}
                    error={!!formErrors.videoLanguage}
                    placeholder="Please choose"
                    startDecorator={<Video />}
                  >
                    <Option value="en">English</Option>
                    <Option value="zh">Chinese</Option>
                    <Option value="es">Spanish</Option>
                    <Option value="hi">Hindi</Option>
                    <Option value="ar">Arabic</Option>
                    <Option value="pt">Portuguese</Option>
                    <Option value="bn">Bengali</Option>
                    <Option value="ru">Russian</Option>
                    <Option value="ja">Japanese</Option>
                    <Option value="pa">Punjabi</Option>
                    <Option value="de">German</Option>
                    <Option value="jv">Javanese</Option>
                    <Option value="ko">Korean</Option>
                    <Option value="fr">French</Option>
                    <Option value="tr">Turkish</Option>
                    <Option value="vi">Vietnamese</Option>
                    <Option value="te">Telugu</Option>
                    <Option value="mr">Marathi</Option>
                    <Option value="ta">Tamil</Option>
                    <Option value="ur">Urdu</Option>
                    <Option value="gu">Gujarati</Option>
                    <Option value="it">Italian</Option>
                    <Option value="th">Thai</Option>
                    <Option value="fa">Persian</Option>
                    <Option value="pl">Polish</Option>
                    <Option value="uk">Ukrainian</Option>
                  </Select>
                  {formErrors.videoLanguage && <Typography color="danger" level="body3">{formErrors.videoLanguage}</Typography>}
                </Grid>
                <Grid xs={6}>
                  <Typography level="body2" className="mb-2">Choose blog language:</Typography>
                  <Select
                    fullWidth
                    value={blogLanguage}
                    onChange={(_, value) => setBlogLanguage(value)}
                    error={!!formErrors.blogLanguage}
                    placeholder="Please choose"
                    startDecorator={<FileText />}
                  >
                    <Option value="English">English</Option>
                    <Option value="繁體中文">繁體中文</Option>
                    <Option value="简体中文">简体中文</Option>
                    <Option value="Español">Español</Option>
                    <Option value="हिन्दी">हिन्दी</Option>
                    <Option value="العربية">العربية</Option>
                    <Option value="Português">Português</Option>
                    <Option value="বাংলা">বাংলা</Option>
                    <Option value="Русский">Русский</Option>
                    <Option value="日本語">日本語</Option>
                    <Option value="ਪੰਜਾਬੀ">ਪੰਜਾਬੀ</Option>
                    <Option value="Deutsch">Deutsch</Option>
                    <Option value="Italiano">Italiano</Option>
                    <Option value="한국어">한국어</Option>
                    <Option value="Français">Français</Option>
                    <Option value="Türkçe">Türkçe</Option>
                    <Option value="Tiếng Việt">Tiếng Việt</Option>
                  </Select>
                  {formErrors.blogLanguage && <Typography color="danger" level="body3">{formErrors.blogLanguage}</Typography>}
                </Grid>
                <Grid xs={12}>
                  <Typography level="body2" className="mb-2">Number of screenshots included in content:</Typography>
                  <Slider
                    value={targetImagesCount}
                    onChange={(_, newValue) => setTargetImagesCount(newValue)}
                    min={0}
                    max={10}
                    step={1}
                    marks
                    valueLabelDisplay="auto"
                  />
                </Grid>
                <Grid xs={12}>
                 <Accordion 
                    expanded={showAdvancedFeatures} 
                    onChange={() => setShowAdvancedFeatures(!showAdvancedFeatures)}
                    sx={{
                      borderRadius: 'sm',
                    }}
                  >
                    <AccordionSummary
                      indicator={<ChevronDown />}
                      sx={{
                        '& .MuiAccordionSummary-expandIconWrapper': {
                          order: -1,
                          marginRight: 1,
                          color: 'primary.main',
                        },
                        '&:hover': {
                          bgcolor: 'background.level1',
                        },
                      }}
                    >
                      <Typography level="body1" color="primary" fontWeight="bold">
                        Advanced Features
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2} alignItems="center">
                        <Grid xs={12} container alignItems="center" spacing={1}>
                          <Grid xs={3} display="flex" alignItems="center">
                            <Typography level="body2" sx={{ mr: 1 }}>SEO Keywords:</Typography>
                            <Tooltip title="AI will try to incorporate keywords into article, ideally at least 3 keywords" placement="top">
                              <BookOpen size={16} />
                            </Tooltip>
                          </Grid>
                          <Grid xs={8}>
                            <Tooltip title="Enter keywords, separated by commas, then click ✓ to confirm and generate related keywords." placement="top">
                              <Input
                                fullWidth
                                size="sm"
                                value={targetKeywords}
                                onChange={handleKeywordChange}
                                onFocus={handleKeywordFocus}
                                placeholder="Enter keywords, separated by commas"
                                error={showLanguageAlert}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid xs={1}>
                            <IconButton
                              size="sm"
                              onClick={handleConfirmKeywords}
                              disabled={!hasKeywords || isGeneratingKeywords || !blogLanguage}
                              sx={{
                                animation: hasKeywords && blogLanguage && !keywordsConfirmed ? `${pulseAnimation} 1.5s infinite` : 'none',
                                '&:hover': {
                                  animation: 'none',
                                  bgcolor: 'primary.main',
                                  color: 'white',
                                },
                                '&:disabled': {
                                  animation: 'none',
                                },
                                transition: 'all 0.3s',
                              }}
                              title="Confirm keywords and generate related keywords"
                            >
                              {isGeneratingKeywords ? (
                                <CircularProgress size="sm" />
                              ) : (
                                <Check size={16} />
                              )}
                            </IconButton>
                          </Grid>
                        </Grid>
                        {showLanguageAlert && (
                          <Grid xs={12}>
                            <Alert
                              color="warning"
                              startDecorator={<AlertCircle />}
                              sx={{ mt: 2 }}
                              onClose={() => setShowLanguageAlert(false)}
                            >
                              Please select a blog language before entering keywords.
                            </Alert>
                          </Grid>
                        )}
                        {showGeneratingAlert && (
                          <Grid xs={12}>
                            <Alert
                              color="success"
                              startDecorator={<Lightbulb />}
                              sx={{ mt: 2 }}
                            >
                              Generating related keywords for you...
                            </Alert>
                          </Grid>
                        )}
                        {(selectedKeywords.length > 0 || generatedKeywords.length > 0) && (
                          <Grid xs={12}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                                mt: 1,
                              }}
                            >
                              {[...new Set([...selectedKeywords, ...generatedKeywords])].map((keyword, index) => {
                                const isSelected = selectedKeywords.includes(keyword);
                                return (
                                  <Chip
                                    key={index}
                                    size="sm"
                                    variant="soft"
                                    color={isSelected ? 'primary' : 'neutral'}
                                    startDecorator={
                                      isSelected && <Check size={12} style={{ zIndex: 1, pointerEvents: 'none' }} />
                                    }
                                  >
                                    <Checkbox
                                      size="sm"
                                      variant="outlined"
                                      color={isSelected ? 'primary' : 'neutral'}
                                      disableIcon
                                      overlay
                                      label={keyword}
                                      checked={isSelected}
                                      onChange={() => handleKeywordToggle(keyword)}
                                      slotProps={{
                                        root: { sx: { fontSize: '0.75rem', p: 0 } },
                                        label: { sx: { fontSize: '0.75rem', p: '1px 2px' } },
                                      }}
                                    />
                                  </Chip>
                                );
                              })}
                            </Box>
                          </Grid>
                        )}
                        <Grid xs={12} container alignItems="center" spacing={1}>
                          <Grid xs={3} display="flex" alignItems="center">
                            <Typography level="body2" sx={{ mr: 1 }}>Generate Quiz:</Typography>
                            <Tooltip title="This Quiz feature will launch soon!" placement="top">
                              <HelpCircle size={16} />
                            </Tooltip>
                          </Grid>
                          <Grid xs={9}>
                            <Switch
                              size="sm"
                              checked={generateQuiz}
                              onChange={(event) => setGenerateQuiz(event.target.checked)}
                              disabled={true} // Disable the switch
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid xs={12}>
                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <Box sx={{ width: '100%', mt: 2 }}>
                      <Typography level="body2">Uploading: {uploadProgress}%</Typography>
                      <LinearProgress determinate value={uploadProgress} />
                    </Box>
                  )}
                  <AwesomeButton
                    type="primary"
                    onPress={onGenerate}
                    disabled={isGenerating || isLoading}
                    style={{ width: '100%' }}
                  >
                    {isGenerating || isLoading ? (
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress size="sm" color="neutral" sx={{ mr: 1 }} />
                        {uploadProgress > 0 && uploadProgress < 100 ? 'Uploading...' : 'Working on it...🏃'}
                      </Box>
                    ) : (
                      'Generate'
                    )}
                  </AwesomeButton>
                </Grid>
              </Grid>
              {error && <Typography color="danger" className="mt-4">{error}</Typography>}
            </Sheet>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CreateContent;