import React from 'react';
import { ArticleProvider } from '../contexts/ArticleContext';
import ArticlesContent from './ArticlesContent';

const MyArticles = () => {
  return (
    <ArticleProvider>
      <ArticlesContent />
    </ArticleProvider>
  );
};

export default MyArticles;