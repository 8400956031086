import React from 'react';
import { Table, Skeleton } from '@mui/joy';

const ArticleListSkeleton = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: '5%' }}><Skeleton variant="rectangular" width={20} height={20} /></th>
          <th style={{ width: '20%' }}><Skeleton variant="text" width={100} /></th>
          <th style={{ width: '20%' }}><Skeleton variant="text" width={100} /></th>
          <th style={{ width: '10%' }}><Skeleton variant="text" width={50} /></th>
          <th style={{ width: '10%' }}><Skeleton variant="text" width={50} /></th>
          <th style={{ width: '10%' }}><Skeleton variant="text" width={50} /></th>
          <th style={{ width: '25%' }}><Skeleton variant="text" width={100} /></th>
        </tr>
      </thead>
      <tbody>
        {[...Array(10)].map((_, index) => (
          <tr key={index}>
            <td><Skeleton variant="rectangular" width={20} height={20} /></td>
            <td><Skeleton variant="text" width={150} /></td>
            <td><Skeleton variant="text" width={150} /></td>
            <td><Skeleton variant="text" width={80} /></td>
            <td><Skeleton variant="text" width={80} /></td>
            <td><Skeleton variant="text" width={80} /></td>
            <td><Skeleton variant="rectangular" width={200} height={30} /></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ArticleListSkeleton;