import React, { useEffect, useMemo } from 'react';
import { Typography, LinearProgress } from '@mui/joy';
import { useArticleContext } from '../contexts/ArticleContext';
import ArticleList from '../components/ArticleList';
import BulkActions from '../components/BulkActions';
import Pagination from '../components/Pagination';
import PublishToWordPress from '../components/PublishToWordPress';
import Notification from '../components/Notification';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';

const ArticlesContent = React.memo(() => {
  const { 
    articles, 
    loading, 
    error, 
    totalPages, 
    currentPage,
    handlePageChange,
    fetchArticles,  
    openPublishModal,
    closePublishModal,
    articleToPublish,
    notification,
    closeNotification
  } = useArticleContext();

  useEffect(() => {
    if (currentPage > 0) {
      fetchArticles(currentPage);
    }
  }, [fetchArticles, currentPage]);

  const memoizedPublishToWordPress = useMemo(() => (
    <PublishToWordPress
      open={openPublishModal}
      onClose={closePublishModal}
      articles={articleToPublish ? (Array.isArray(articleToPublish) ? articleToPublish : [articleToPublish]) : []}
    />
  ), [openPublishModal, closePublishModal, articleToPublish]);

  const memoizedNotification = useMemo(() => (
    notification && notification.show ? (
      <Notification 
        onClose={closeNotification}
        title={notification.title}
        message={notification.message}
        color={notification.color}
      />
    ) : null
  ), [notification, closeNotification]);

  const memoizedBulkActions = useMemo(() => <BulkActions />, []);

  const memoizedArticleList = useMemo(() => <ArticleList articles={articles} />, [articles]);

  const memoizedDeleteConfirmationModal = useMemo(() => <DeleteConfirmationModal />, []);

  if (loading) return <LinearProgress />;
  if (error) return <Typography color="danger" level="h6">Error: {error}</Typography>;

  return (
    <>
      {memoizedBulkActions}
      {memoizedArticleList}
      <Pagination 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={handlePageChange} 
      />
      {memoizedPublishToWordPress}
      {memoizedNotification}
      {memoizedDeleteConfirmationModal}
    </>
  );
});

export default ArticlesContent;