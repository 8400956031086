import React, { useEffect } from 'react';
import { Alert } from '@mui/joy';

function FadeAlert({ message, severity, onClose }) {
  useEffect(() => {
    if (onClose) {
      const timer = setTimeout(() => {
        onClose();
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [onClose]);

  return (
    <Alert color={severity} sx={{ mb: 2 }}>
      {message}
    </Alert>
  );
}

export default FadeAlert;