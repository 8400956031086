import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Alert, CircularProgress, Stack } from '@mui/joy';
import RefreshIcon from '@mui/icons-material/Refresh';
import LogoutIcon from '@mui/icons-material/Logout';
import { useGoogleForms } from '../contexts/GoogleFormsContext';
import FadeAlert from './FadeAlert';

function GoogleFormsIntegration() {
  const { status, error, initiateGoogleFormsAuth, disconnectGoogleForms, checkStatus } = useGoogleForms();
  const [alert, setAlert] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) return;
      if (event.data.type === 'GOOGLE_AUTH_SUCCESS') {
        setAlert({ message: 'Successfully connected to Google Forms', severity: 'success' });
        checkStatus();
      } else if (event.data.type === 'GOOGLE_AUTH_ERROR') {
        setAlert({ message: `Failed to connect: ${event.data.error}`, severity: 'error' });
      }
      setIsConnecting(false);
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [checkStatus]);

  const handleConnect = async () => {
    setIsConnecting(true);
    await initiateGoogleFormsAuth();
  };

  const handleDisconnect = async () => {
    try {
      await disconnectGoogleForms();
      setAlert({ message: 'Successfully disconnected from Google Forms', severity: 'success' });
    } catch (error) {
      setAlert({ message: `Failed to disconnect: ${error.message}`, severity: 'error' });
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <CircularProgress />;
      case 'not_connected':
        return (
          <Button 
            onClick={handleConnect} 
            startDecorator={<RefreshIcon />}
            loading={isConnecting}
          >
            Connect to Google Forms
          </Button>
        );
      case 'connected':
        return (
          <Box sx={{ bgcolor: 'background.level1', p: 2, borderRadius: 'sm' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography level="body1" sx={{ flexGrow: 1 }}>
                Google Forms is connected.
              </Typography>
              <Button 
                onClick={handleConnect} 
                startDecorator={<RefreshIcon />} 
                variant="outlined" 
                color="success"
              >
                Reconnect
              </Button>
              <Button 
                onClick={handleDisconnect} 
                color="danger" 
                startDecorator={<LogoutIcon />} 
                variant="outlined"
              >
                Disconnect
              </Button>
            </Stack>
          </Box>
        );
      case 'expired':
        return (
          <Box sx={{ bgcolor: 'background.level1', p: 2, borderRadius: 'sm' }}>
            <Typography level="body1" sx={{ mb: 2 }}>
              Your Google Forms connection has expired.
            </Typography>
            <Button 
              onClick={handleDisconnect} 
              color="danger" 
              startDecorator={<LogoutIcon />} 
              variant="outlined" 
              sx={{ mr: 2 }}
            >
              Disconnect
            </Button>
            <Button 
              onClick={handleConnect} 
              startDecorator={<RefreshIcon />} 
              variant="outlined" 
              color="success"
            >
              Reconnect to Google Forms
            </Button>
          </Box>
        );
      case 'error':
        return (
          <>
            <Typography level="body1" sx={{ mb: 2 }}>
              An error occurred while checking your Google Forms connection.
            </Typography>
            <Button 
              onClick={checkStatus} 
              startDecorator={<RefreshIcon />}
            >
              Try Again
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography level="h4" component="h1" sx={{ mb: 2 }}>Google Forms Integration</Typography>
      <Typography level="body2" sx={{ mb: 2 }}>
        Connect to Google Forms to enable quiz generation for your blog posts.
      </Typography>
      {alert && (
        <FadeAlert
          message={alert.message}
          severity={alert.severity}
          onClose={() => setAlert(null)}
        />
      )}
      {error && (
        <Alert color="danger" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {renderContent()}
    </Box>
  );
}

export default React.memo(GoogleFormsIntegration);