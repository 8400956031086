import React from 'react';
import { Modal, ModalDialog, Typography, Button } from '@mui/joy';
import { useArticleContext } from '../contexts/ArticleContext';

const DeleteConfirmationModal = React.memo(() => {
  const { openDeleteModal, setOpenDeleteModal, articlesToDelete, confirmDelete } = useArticleContext();

  return (
    <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
      <ModalDialog>
        <Typography level="h4">Confirm Delete</Typography>
        <Typography>
          Are you sure you want to delete {articlesToDelete.length} article(s)? This action cannot be undone.
        </Typography>
        <Button color="danger" onClick={confirmDelete}>Delete</Button>
        <Button color="neutral" onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
      </ModalDialog>
    </Modal>
  );
});

export default DeleteConfirmationModal;