import { useState, useCallback, useRef } from 'react';

const useNotification = (defaultDuration = 3000) => {
  const [notification, setNotification] = useState({ show: false, title: '', message: '', color: 'success' });
  const timeoutRef = useRef(null);

  const showNotification = useCallback(({ title, message, color = 'success', duration = defaultDuration }) => {
    console.log('Showing notification:', { title, message, color, duration });
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setNotification({ show: true, title, message, color });
    timeoutRef.current = setTimeout(() => {
      console.log('Notification timeout reached, hiding notification');
      setNotification(prev => ({ ...prev, show: false }));
    }, duration);
  }, [defaultDuration]);

  const closeNotification = useCallback(() => {
    console.log('Manually closing notification');
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setNotification(prev => ({ ...prev, show: false }));
  }, []);

  return { notification, showNotification, closeNotification };
};

export default useNotification;