import React from 'react';
import { Alert, AlertTitle, Box, Typography } from '@mui/joy';

const Notification = React.memo(({ onClose, title, message, color = 'success' }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 20,
        right: 20,
        zIndex: 9999,
        maxWidth: '400px',
        width: '100%'
      }}
    >
      <Alert
        variant="soft"
        color={color}
        onClose={onClose}
        sx={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
      >
        <Typography fontWeight="bold">{title}</Typography>
        {message}
      </Alert>
    </Box>
  );
});

export default Notification;