import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useCallback } from 'react'
import api from '../utils/api'

const WORDPRESS_SETTINGS_QUERY_KEY = 'wordpressSettings'

const useWordPressSettings = () => {
  const queryClient = useQueryClient()

  const { data: settings, isLoading, error, refetch } = useQuery(WORDPRESS_SETTINGS_QUERY_KEY, async () => {
    try {
      const credentials = await api.get('/api/wordpress-credentials');
      
      if (!credentials.data || !credentials.data.siteUrl) {
        // User is not connected, return early with default state
        return {
          isConnected: false,
          credentials: null,
          postingSettings: null
        };
      }

      // User is connected, fetch additional data
      const [postingSettings, categoriesAndTags] = await Promise.all([
        api.get('/api/wordpress-posting-settings'),
        api.get('/api/wordpress-categories-tags')
      ]);

      return {
        isConnected: true,
        credentials: credentials.data,
        postingSettings: {
          defaultSettings: postingSettings.data,
          categoriesAndTags: categoriesAndTags.data
        }
      };
    } catch (error) {
      console.error('Error fetching WordPress settings:', error);
      if (error.response && error.response.status === 404) {
        // Handle 404 error (new user or not connected)
        return {
          isConnected: false,
          credentials: null,
          postingSettings: null
        };
      }
      throw error; // Rethrow other errors
    }
  }, {
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    retry: false, // Disable retries on failure
  })

  const { data: postingSettings, isLoading: isPostingSettingsLoading, error: postingSettingsError } = useQuery(
    'wordpress-posting-settings',
    async () => {
      const res = await api.get('/api/wordpress-posting-settings');
      return res.data;
    },
    {
      enabled: !!settings?.isConnected,
    }
  );

  const { data: categoriesAndTags, isLoading: isCategoriesAndTagsLoading, error: categoriesAndTagsError } = useQuery(
    'wordpress-categories-tags',
    async () => {
      const res = await api.get('/api/wordpress-categories-tags');
      return res.data;
    },
    {
      enabled: !!settings?.isConnected,
    }
  );

  const refreshSettings = useCallback(async () => {
    await queryClient.invalidateQueries(WORDPRESS_SETTINGS_QUERY_KEY);
    await queryClient.invalidateQueries('wordpress-posting-settings');
    await queryClient.invalidateQueries('wordpress-categories-tags');
    return refetch();
  }, [queryClient, refetch]);

  const loadWordPressSettings = useCallback(async () => {
    console.log('loadWordPressSettings called');
    const result = await refetch();
    console.log('WordPress settings refetched:', result);
    return result;
  }, [refetch]);

  const saveCredentialsMutation = useMutation(
    (credentials) => api.post('/api/wordpress-credentials', credentials),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WORDPRESS_SETTINGS_QUERY_KEY)
      }
    }
  )

  const savePostingSettingsMutation = useMutation(
    (data) => api.post('/api/wordpress-posting-settings', data),
    {
      onSuccess: (newPostingSettings) => {
        queryClient.setQueryData('wordpress-posting-settings', newPostingSettings);
      }
    }
  )

  const disconnectMutation = useMutation(
    () => api.delete('/api/wordpress-credentials'),
    {
      onSuccess: () => {
        console.log('WordPress disconnected successfully');
        queryClient.setQueryData(WORDPRESS_SETTINGS_QUERY_KEY, {
          isConnected: false,
          credentials: null,
          postingSettings: null
        });
        queryClient.invalidateQueries(WORDPRESS_SETTINGS_QUERY_KEY);
      },
      onError: (error) => {
        console.error('Error disconnecting WordPress:', error);
        // You might want to throw the error here to be caught by the component
        throw error;
      },
      onSettled: () => {
        // This runs regardless of success or failure
        console.log('Disconnect operation completed');
      }
    }
  );

  const testConnection = async (credentials) => {
    console.log('testConnection called with:', credentials);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/test-wordpress-connection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });
    if (!response.ok) {
      throw new Error(`HTTP Error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log('testConnection response:', data);
    return data;
  };

  return {
    settings,
    isLoading,
    error,
    loadWordPressSettings,
    saveCredentials: saveCredentialsMutation.mutate,
    savePostingSettings: savePostingSettingsMutation.mutate,
    disconnect: disconnectMutation.mutate,
    testConnection,
    refreshSettings
  }
}

export default useWordPressSettings