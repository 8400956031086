import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import PocketBase from 'pocketbase';
import { debounce } from 'lodash';

const useArticles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const abortControllerRef = useRef(null);
  const pb = useMemo(() => new PocketBase(process.env.REACT_APP_PB_URL || 'https://log.8motions.com'), []);

  const debouncedFetchArticles = useMemo(
    () => debounce(async (page) => {
      console.log('Fetching articles for page:', page);
      setLoading(true);
      setError(null);
      
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      try {
        const token = localStorage.getItem('pocketbase_token');
        if (!token) {
          throw new Error('User not authenticated');
        }
    
        pb.authStore.save(token, null);
    
        const resultList = await pb.collection('VideoProcessingLogs').getList(page, rowsPerPage, {
          sort: '-created',
          expand: 'user',
          fields: 'id,video_title,video_url,status,video_duration,total_processing_time,html_content,progress,wordpress_status,quiz_link',
          requestKey: `articles_page_${page}`,
          signal: abortControllerRef.current.signal
        });
    
        setArticles(resultList.items);
        setTotalPages(Math.ceil(resultList.totalItems / rowsPerPage));
        setCurrentPage(page);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error in fetchArticles:', error);
          setError(`Failed to fetch articles: ${error.message}`);
          setArticles([]);
        } else {
          console.log('Request was cancelled, ignoring.');
        }
      } finally {
        setLoading(false);
      }
    }, 300),
    [pb, rowsPerPage]
  );

  const fetchArticles = useCallback((page) => {
    debouncedFetchArticles(page);
  }, [debouncedFetchArticles]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  useEffect(() => {
    debouncedFetchArticles(currentPage);

    // Set up realtime subscription
    const unsubscribe = pb.collection('VideoProcessingLogs').subscribe('*', function (e) {
      if (e.action === 'update') {
        setArticles(prevArticles => 
          prevArticles.map(article => 
            article.id === e.record.id ? { ...article, ...e.record, progress: Math.max(5, e.record.progress || 0) } : article
          )
        );
      }
    });

    // Cleanup function
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [currentPage, debouncedFetchArticles, pb]);

  return { articles, loading, error, totalPages, currentPage, handlePageChange, fetchArticles };
};

export default useArticles;