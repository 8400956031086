import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getGoogleAuthUrl, saveGoogleToken, disconnectGoogleAccount, checkGoogleTokenStatus } from '../utils/api';

export const GoogleFormsContext = createContext();

export function useGoogleForms() {
  return useContext(GoogleFormsContext);
}

export function GoogleFormsProvider({ children }) {
  const queryClient = useQueryClient();
  const [error, setError] = useState(null);

  const { data: status, refetch: checkStatus } = useQuery('googleFormsStatus', checkGoogleTokenStatus, {
    onError: (err) => {
      console.error('Error checking Google Forms status:', err);
      setError('Failed to check Google Forms connection status');
    },
  });

  const initiateGoogleFormsAuth = useCallback(async () => {
    try {
      const authUrl = await getGoogleAuthUrl();
      const popup = window.open(authUrl, 'GoogleAuthPopup', 'width=500,height=600');
      
      if (!popup || popup.closed || typeof popup.closed == 'undefined') {
        throw new Error('Popup blocked. Please allow popups for this site.');
      }
    } catch (error) {
      console.error('Error initiating Google Forms auth:', error);
      setError('Failed to initiate Google Forms connection. Please try again.');
    }
  }, []);

  const disconnectMutation = useMutation(disconnectGoogleAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('googleFormsStatus');
    },
    onError: (error) => {
      console.error('Error disconnecting from Google Forms:', error);
      setError('Failed to disconnect from Google Forms');
    },
  });

  const value = {
    status: status?.status || 'loading',
    error,
    initiateGoogleFormsAuth,
    disconnectGoogleForms: disconnectMutation.mutate,
    checkStatus,
  };

  return (
    <GoogleFormsContext.Provider value={value}>
      {children}
    </GoogleFormsContext.Provider>
  );
}