import axios from 'axios';

// Define API_BASE_URL
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
console.log('API_BASE_URL:', API_BASE_URL);

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('pocketbase_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.status === 401) {
    // Clear the token and redirect to login page
    localStorage.removeItem('pocketbase_token');
    localStorage.removeItem('pocketbase_auth');
    window.location.href = '/login';
  }
  throw error;
});

// Add this helper function at the top of the file
const getAuthToken = () => localStorage.getItem('pocketbase_token');

export const checkGoogleTokenStatus = async () => {
  const token = localStorage.getItem('pocketbase_token');
  if (!token) {
    return { isConnected: false };
  }
  try {
    const response = await api.get('/api/google-token-status');
    return response.data;
  } catch (error) {
    console.error('Error checking Google token status:', error);
    return { isConnected: false };
  }
};

export const getGoogleAuthUrl = async () => {
  try {
    console.log('Requesting Google Auth URL from:', `${API_BASE_URL}/api/initiate-google-auth`);
    const response = await api.get('/api/initiate-google-auth');
    console.log('Google Auth URL response:', response.data);
    if (response.data && response.data.url) {
      return response.data.url;
    } else {
      throw new Error('Invalid response from server');
    }
  } catch (error) {
    console.error('Error getting Google auth URL:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

// Then update functions like disconnectGoogleAccount to use it:
export const disconnectGoogleAccount = async () => {
  const token = getAuthToken();
  if (!token) {
    throw new Error('No authentication token found');
  }
  try {
    const response = await api.post('/api/disconnect-google-account', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error disconnecting Google account:', error);
    throw error;
  }
};

let isSavingToken = false;

export const saveGoogleToken = async (code) => {
  if (isSavingToken) {
    console.log('Token save already in progress');
    return;
  }

  isSavingToken = true;

  try {
    const token = localStorage.getItem('pocketbase_token');
    const response = await api.post('/api/save-google-token', { code }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Google token saved successfully');
    return response.data;
  } catch (error) {
    console.error('Error saving Google token:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      throw new Error(error.response.data.error || 'Failed to save Google token');
    } else if (error.request) {
      throw new Error('No response received from server');
    } else {
      throw error;
    }
  } finally {
    isSavingToken = false;
  }
};

export const refreshGoogleToken = async () => {
  try {
    const response = await api.post('/api/refresh-google-token');
    if (response.data.success) {
      console.log('Token refreshed successfully');
      return true;
    } else {
      console.error('Failed to refresh token:', response.data.error);
      return false;
    }
  } catch (error) {
    console.error('Error refreshing Google token:', error);
    return false;
  }
};

export const handleGenerate = async (input, videoLanguage, blogLanguage, generateQuiz, targetKeywords, targetImagesCount) => {
  try {
    console.log(`Generating blog for input: ${input}, Video Language: ${videoLanguage}, Blog Language: ${blogLanguage}, Generate Quiz: ${generateQuiz}, Target Keywords: ${targetKeywords}, Target Images Count: ${targetImagesCount}`);
    
    let formData = new FormData();
    if (input instanceof File) {
      formData.append('video', input);
    } else if (typeof input === 'string' && input.trim() !== '') {
      formData.append('url', input.trim());
    } else {
      throw new Error('Invalid input: Please provide a valid file or YouTube URL');
    }
    formData.append('videoLanguage', videoLanguage);
    formData.append('blogLanguage', blogLanguage);
    formData.append('generateQuiz', generateQuiz.toString());
    formData.append('targetKeywords', targetKeywords);
    formData.append('targetImagesCount', targetImagesCount.toString());

    const response = await api.post('/api/generate-blog', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.job_id;
  } catch (error) {
    console.error('Error in handleGenerate:', error);
    throw error;
  }
};

export const checkJobStatus = async (jobId) => {
  if (typeof jobId !== 'string') {
    console.error('Invalid jobId:', jobId);
    throw new Error('Invalid jobId');
  }

  console.log(`Checking job status for job ID: ${jobId}`);

  try {
    const response = await api.get(`/api/job-status/${jobId}`);

    if (response.data && response.data.status) {
      return {
        status: response.data.status,
        progress: response.data.progress || 0,
        result: response.data.result
      };
    } else {
      throw new Error("Unexpected response format from server");
    }
  } catch (error) {
    console.error("Error in checkJobStatus", error);
    throw new Error(`Error checking job status: ${error.message}`);
  }
};

// New function for publishing to WordPress
export const publishToWordPress = async (articleId, options) => {
  console.log(`Publishing article ID: ${articleId} to WordPress with options:`, options);

  try {
    if (!articleId) {
      throw new Error('Article ID is required');
    }
    const response = await api.post('/api/publish-to-wordpress', {
      articleId,
      ...options
    });

    console.log('Server response:', response.data);

    if (response.data && (response.data.wpPostId || response.data.wordpress_post_id)) {
      return {
        wpPostId: response.data.wpPostId || response.data.wordpress_post_id,
        status: response.data.status || 'published'
      };
    } else {
      throw new Error("Unexpected response format from server: " + JSON.stringify(response.data));
    }
  } catch (error) {
    console.error("Error in publishToWordPress", error);
    if (error.response) {
      console.error("Server error response:", error.response.data);
      throw new Error(`Server error: ${error.response.data.error || error.response.statusText}`);
    } else if (error.request) {
      throw new Error("No response received from server. Please check your network connection.");
    } else {
      throw error; // Re-throw the original error if it's not an Axios error
    }
  }
};

export const uploadFileInChunks = async (file, videoLanguage, blogLanguage, generateQuiz, targetKeywords, targetImagesCount, onProgress) => {
  console.log(`Starting upload for file: ${file.name}, size: ${file.size} bytes`);
  const chunkSize = 4 * 1024 * 1024; // 5MB
  const totalChunks = Math.ceil(file.size / chunkSize);
  const fileId = Date.now().toString(); // Unique file ID
  const maxConcurrentUploads = 3; // Number of parallel uploads

  let uploadedChunks = 0;

  const uploadChunk = async (chunkNumber) => {
    const start = chunkNumber * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append('chunk', chunk, `chunk-${chunkNumber}`);
    formData.append('chunkNumber', chunkNumber.toString());
    formData.append('totalChunks', totalChunks.toString());
    formData.append('fileId', fileId);
    formData.append('videoLanguage', videoLanguage);
    formData.append('blogLanguage', blogLanguage);
    formData.append('generateQuiz', generateQuiz.toString());
    formData.append('targetKeywords', targetKeywords);
    formData.append('targetImagesCount', targetImagesCount.toString());

    try {
      console.log(`Uploading chunk ${chunkNumber + 1}/${totalChunks}`);
      const response = await api.post('/api/upload-chunk', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 60000 // 60 seconds timeout
      });
      console.log(`Chunk ${chunkNumber + 1}/${totalChunks} uploaded successfully`);
      uploadedChunks++;
      onProgress(Math.round((uploadedChunks / totalChunks) * 100));
      return response;
    } catch (error) {
      console.error(`Error uploading chunk ${chunkNumber + 1}/${totalChunks}:`, error);
      throw error;
    }
  };

  const uploadChunksInParallel = async () => {
    for (let i = 0; i < totalChunks; i += maxConcurrentUploads) {
      const uploadPromises = [];
      for (let j = 0; j < maxConcurrentUploads && i + j < totalChunks; j++) {
        uploadPromises.push(uploadChunk(i + j));
      }
      await Promise.all(uploadPromises);
    }
  };

  try {
    console.log('Starting parallel chunk upload');
    const startTime = Date.now();
    await uploadChunksInParallel();
    const endTime = Date.now();
    const uploadDuration = (endTime - startTime) / 1000; // in seconds
    console.log(`File upload completed successfully in ${uploadDuration} seconds`);

    // Start processing after all chunks are uploaded
    console.log('All chunks uploaded, starting processing');
    const response = await api.post('/api/process-uploaded-video', { 
      fileId,
      totalChunks,
      videoLanguage,
      blogLanguage,
      generateQuiz,
      targetKeywords,
      targetImagesCount
    });
    console.log('Processing started, job ID:', response.data.job_id, 'record ID:', response.data.record_id);
    const { job_id: jobId, record_id: recordId } = response.data;
    return { jobId, recordId, uploadDuration };
  } catch (error) {
    console.error('File upload failed:', error);
    throw error;
  }
};

export const testWordPressConnection = async (siteUrl, username, applicationPassword) => {
  try {
    const response = await api.post('/api/test-wordpress-connection', {
      siteUrl, username, applicationPassword
    });
    return response.data;
  } catch (error) {
    console.error('Error testing WordPress connection:', error);
    throw error;
  }
};

export const saveWordPressCredentials = async (siteUrl, username, applicationPassword) => {
  try {
    const response = await api.post('/api/wordpress-credentials', {
      siteUrl, username, applicationPassword
    });
    return response.data;
  } catch (error) {
    console.error('Error saving WordPress credentials:', error);
    throw error;
  }
};

export const getWordPressCredentials = async () => {
  try {
    const response = await api.get('/api/wordpress-credentials');
    return response.data;
  } catch (error) {
    console.error('Error fetching WordPress credentials:', error);
    throw error;
  }
};

export const getWordPressPostingSettings = async () => {
  try {
    const response = await api.get('/api/wordpress-posting-settings');
    return response.data;
  } catch (error) {
    console.error('Error fetching WordPress posting settings:', error);
    throw error;
  }
};

export const getWordPressCategoriesAndTags = async () => {
  try {
    const response = await api.get('/api/wordpress-categories-tags');
    return response.data;
  } catch (error) {
    console.error('Error fetching WordPress categories and tags:', error);
    throw error;
  }
};

export const disconnectWordPress = async () => {
  try {
    const response = await api.delete('/api/wordpress-credentials');
    return response.data;
  } catch (error) {
    console.error('Error disconnecting WordPress:', error);
    throw error;
  }
};

export const generateRelatedKeywords = async (keywords, blogLanguage) => {
  try {
    const response = await api.post('/api/generate-related-keywords', { keywords, blog_language: blogLanguage });
    return response.data;
  } catch (error) {
    console.error('Error generating related keywords:', error);
    throw error;
  }
};

export const getYouTubeVideoInfo = async (url) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/youtube-info?url=${encodeURIComponent(url)}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch video info');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching YouTube video info:', error);
    throw error;
  }
};

export default api;